import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/cart/prouct_cart_view";
import Services from "../../Services/Services";

export default class ProductCartView extends Component {
  id = "procut-cart-view";
  group = "wishlist";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }

  updateCart(product, amount) {
    Services.get("order").then(async ([orderService]) => {
      const { instance, items } = product;
      const { id, ingredients = {} } = product.item;

      const newIngredients = ingredients
        .filter((ingredient) => {
          return ingredient.selected;
        })
        .map((ingredient) => {
          return ingredient.item._values.slug;
        });

      await orderService.updateItem({
        itemId: id,
        itemType: "product",
        itemInstance: instance,
        items,
        values: {
          ingredients: newIngredients,
        },
        amount,
      });

      this.getComponents()
        .findByGroup("order")
        .forEach((order) => {
          order.setData({ "default.order": orderService.getOrder() });
        });
    });
  }

  // toggleProductDialog() {
  //   var { product = {}, ingredient_types = [] } = { ...this.props };

  //   this.getComponents()
  //     .findById("product-dialog")
  //     .first()
  //     .setProduct(JSON.parse(JSON.stringify(product)), ingredient_types);
  // }

  toggleProductDialog() {
    let { product = {}, ingredient_types = [] } = { ...this.props };

    if (this.isDeal(product)) {
      this.getComponents()
        .findById("deal-dialog")
        .first()
        .setProduct(JSON.parse(JSON.stringify(product)));
    } else {
      this.getComponents()
        .findById("product-dialog")
        .first()
        .setProduct(JSON.parse(JSON.stringify(product)), ingredient_types);
    }
  }

  isDeal(product) {
    let { types = [] } = product.item;
    let type = "default";

    if (types.length) {
      type = types[0].item._values.slug;
    }

    return type === "deal";
  }
}
