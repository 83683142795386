import React from "react";
import { styled } from "@mui/material/styles";
import {
  useScrollTrigger,
  Box,
  Fab,
  Fade,
  Badge,
  Button,
  Avatar,
} from "@mui/material";
import { isMobile } from "react-device-detect";

const IconsButton = styled(Fab)(({ theme }) => ({
  color: "#fff",
  fontSize: 22,
  backgroundColor: "#f05d32",
  marginRight: 5,
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 40,
  "&:hover": {
    backgroundColor: "#f05d32",
    color: "#fff",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": { color: "#f05d32", backgroundColor: "#fff" },
}));

function ScrollTop(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  return (
    <Fade in={trigger}>
      <Box
        sx={{
          position: "fixed",
          bottom: isMobile ? 0 : 16,
          right: isMobile ? "unset" : 16,
          left: isMobile ? 0 : "unset",
          textAlign: "center",
          width: isMobile ? "100%" : "unset",
          padding: "4px",
        }}
      >
        {children}
      </Box>
    </Fade>
  );
}

const MyButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  background: "#f05d32",
  borderRadius: "8px",
  border: "1px solid #f05d32",
  color: "#fff",
  width: "100%",
  "&:hover": {
    background: "#f05d32",
  },
}));

export default function BackToTop(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);

  return (
    <ScrollTop {...props}>
      {isMobile ? (
        <MyButton
          sx={{
            fontSize: { xs: 16, sm: 18 },
            py: 1,
            display: "inline-flex",
            justifyContent: "space-between",
            flexDirection: "row",
            px: 2,
            maxWidth: { xs: "unset", sm: "300px" },
          }}
          disableRipple
          onClick={() => {
            component.toggleCartDrawer();
          }}
        >
          <Avatar
            sx={{
              width: 32,
              height: 32,
              bgcolor: "#000",
              fontSize: { xs: 16, sm: 18 },
              visibility:
                devHelper.getObjectValue(order, "amounts.items", 0) === 0
                  ? "hidden"
                  : "visible",
            }}
          >
            {devHelper.getObjectValue(order, "amounts.items", 0)}
          </Avatar>
          <Box>{component.ucfirst("cart")}</Box>

          <Box
            sx={{
              visibility:
                devHelper.getObjectValue(order, "totals.items", 0) === 0
                  ? "hidden"
                  : "visible",
            }}
          >
            {component
              .getApp()
              .priceFormater(devHelper.getObjectValue(order, "totals.items"))}
          </Box>
        </MyButton>
      ) : (
        <IconsButton
          disableRipple
          onClick={() => {
            component.toggleCartDrawer();
          }}
        >
          <StyledBadge
            badgeContent={devHelper.getObjectValue(order, "amounts.items", 0)}
            showZero
            sx={{
              "& .MuiBadge-badge": {
                visibility:
                  devHelper.getObjectValue(order, "amounts.items", 0) === 0
                    ? "hidden"
                    : "visible",
              },
            }}
          >
            <i className="fa-light fa-bag-shopping" />
          </StyledBadge>
        </IconsButton>
      )}
    </ScrollTop>
  );
}
