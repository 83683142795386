import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Language extends Service {
  getLanguages(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.languages(account, repository).then((r) => {
        const result = this.evaluate(r, "languages");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Language();
