import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Button,
  Dialog,
  TextField,
  IconButton,
  useMediaQuery,
  CardMedia,
  DialogTitle,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#f05d32",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      fullScreen={fullScreen}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: "0px", sm: "25px" },
        },
      }}
    >
      <MyDialogTitle onClose={() => component.props.setOpen(false)}>
        <CardMedia
          component="img"
          image={`${filesUrl}/assets/images/logo.png?v=${img_version}`}
          sx={{
            objectFit: "contain",
            display: "block",
            height: { xs: "16px", sm: "20px", md: "24px" },
          }}
          alt="il Palatino"
        />
      </MyDialogTitle>
      <Container
        maxWidth="xs"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <form onSubmit={(e) => component.sendRequest(e)}>
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: "20px",
              my: 1,
            }}
          >
            {component.ucfirst("contact-form")}
          </Typography>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("fullname-textfield")}
            name="name"
            autoComplete="name"
            helperText={component.getData("error.fields.name")}
            value={component.getData("default.fields.name", "")}
            onChange={(e) => {
              component.setData({ "default.fields.name": e.target.value });
              component.deleteData("error.fields.name");
            }}
            error={component.dataExists("error.fields.name")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("email-textfield")}
            name="email"
            helperText={component.getData("error.fields.email")}
            value={component.getData("default.fields.email", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.email": e.target.value,
              });
              component.deleteData("error.fields.email");
            }}
            error={component.dataExists("error.fields.email")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={`${component.ucfirst(
              "phone-textfield"
            )} ( ${component.ucfirst("optional")} )`}
            name="phone"
            helperText={component.getData("error.fields.phone")}
            value={component.getData("default.fields.phone", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.phone": e.target.value,
              });
              component.deleteData("error.fields.phone");
            }}
            error={component.dataExists("error.fields.phone")}
          />
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("message-textfield")}
            name="message"
            helperText={component.getData("error.fields.message")}
            value={component.getData("default.fields.message", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.message": e.target.value,
              });
              component.deleteData("error.fields.message");
            }}
            error={component.dataExists("error.fields.message")}
          />
          <Button
            type="submit"
            fullWidth
            sx={{
              my: 2,
              padding: "12px 14px",
              background: "#f05d32",
              borderRadius: "12px",
              border: "1px solid #f05d32",
              color: "#ffffff",
              "&:hover": {
                background: "#f05d32",
              },
            }}
          >
            {component.ucfirst("submit")}
          </Button>
        </form>
      </Container>
    </Dialog>
  );
}
