import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "12px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#f05d32",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ForgotPasswordDialog(props) {
  const { component } = props;
  const { open = false } = component.props;

  return (
    <Dialog
      onClose={() => component.props.setOpen(false)}
      open={open}
      maxWidth="xs"
      fullWidth
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "25px",
          minWidth: { xs: "95%", sm: "400px" },
        },
      }}
    >
      <MyDialogTitle
        id="customized-dialog-title"
        onClose={() => component.props.setOpen(false)}
      >
        {component.trans("reset-password-title")}
      </MyDialogTitle>
      <DialogContent sx={{ p: "16px !important" }}>
        <Typography variant="body2">
          {component.trans("reset-password-text-1")}
        </Typography>
        <form onSubmit={(e) => component.requestPassword(e)}>
          <MyTextField
            error={component.dataExists("error.fields.request.username")}
            value={component.getData("default.fields.request.username", "")}
            helperText={component.getData("error.fields.request.username")}
            onChange={(e) => {
              component.setData({
                "default.fields.request.username": e.target.value,
              });
              component.deleteData("error.fields.request.username");
            }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={component.ucfirst("email-textfield")}
            name="email"
          />
          <button type="submit" hidden></button>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: 2, pt: 0 }}>
        <LoginRegisterBtn
          type="submit"
          fullWidth
          variant="contained"
          onClick={() => component.requestPassword()}
        >
          {component.trans("submit-btn")}
        </LoginRegisterBtn>
      </DialogActions>
    </Dialog>
  );
}
