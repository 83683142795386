import Page from "../../../../modules/Core/Components/Page";
import Services from "../../../Services/Services";
import templateDefault from "../../../templates/default/pages/epay/results";

export default class EpayResult extends Page {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    if (this.getQuery("StatusFlag") === "Success") {
      this.fetchOrder();
    } else {
      this.redirect("/checkout?epayerror=1");
    }
  }

  fetchOrder() {
    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.fetchOrder();
        await orderService.verifyPayment(this.getPage().getQuery());

        if (orderService.getData("order.placed")) {
          this.redirect("/");
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              "message-duration": 5000,
              "success-message": `${this.trans(
                "complete-order-title"
              )} ${this.trans("complete-order-subtitle")}`,
            });
        }
      } catch (e) {
        //
      }
    });
  }
}
