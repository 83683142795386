import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/checkout/checkout";
import Services from "../../Services/Services";

export default class Checkout extends Component {
  template = templateDefault;

  group = "order";

  default_address = {
    street: "",
    street_number: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    bell: "",
    floor: "",
    notes: "",
  };

  onLoad(data) {
    super.onLoad(data);

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setOrder(order);

          const { delivery_type = "", items = [] } = order || {};

          if (
            items.length === 0 ||
            (delivery_type === "delivery" &&
              !order.shipping_address &&
              !order.billing_address)
          ) {
            this.getPage().redirect("/");
          } else {
            const { shipping_address = { ...this.default_address } } = order;
            const { user } = order;

            this.setData({
              "default.address": shipping_address,
              "default.user": user,
            });
            this.verifyPayment();
          }
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });

      // orderService
      //   .fetchPaymentTypes()
      //   .then((orderService) => {
      //     const paymentTypes = orderService.getData("paymentTypes");
      //     this.setData({ "default.paymentTypes": paymentTypes });
      //   })
      //   .catch((err) => {
      //     console.log("error", err);
      //   });
    });

    this.retrieveCoupons();
  }

  setOrder(order) {
    const cultery = order.services.find(
      (service) => service.slug === "cutlery"
    );

    this.setHours();

    this.setData({
      "default.cutlery": cultery,
      "default.order": order,
    });

    return this;
  }

  setHours() {
    var hours = [];
    var extra_min = 40;
    var day = new Date(Math.ceil(new Date().getTime() / 300000) * 300000);
    day = this.getCurrentDate(extra_min, day);
    var hr = day.getHours();
    var min = day.getMinutes();

    while (hr < 23 || min < 50) {
      hours.push({ hour: hr, min: min });
      day = this.getCurrentDate(5, day);
      hr = day.getHours();
      min = day.getMinutes().toString();
      min = min.length === 1 ? "0" + min : min;
    }

    this.setData({
      "default.hours": hours,
    });

    return this;
  }

  getCurrentDate(extra_min = 0, date = new Date()) {
    return new Date(date.getTime() + extra_min * 60000);
  }

  updateTime(time) {
    const { order } = this.getData("default", {}) || {};
    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updateTime({
            time,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setData({ "default.order": order });
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }

    return this;
  }

  /* coupons section */

  retrieveCoupons() {
    Services.get("coupon").then(([couponService]) => {
      couponService
        .getCoupons()
        .then((couponService) => {
          const coupons = couponService.getData("coupons", []);
          if (coupons.find((coupon) => coupon.id === "add") === undefined) {
            coupons.push({
              name: this.trans("add-new-coupon"),
              slug: "add",
            });
          }
          this.setData({
            "default.coupons": coupons,
          });
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  activateCoupon(couponId) {
    Services.get("order").then(([orderService]) => {
      orderService
        .updateCoupon({ couponId })
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setOrder(order);
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  updateCouponByCode(e) {
    e.preventDefault();

    const { guest } = this.getData("default");

    var error = {};
    const { couponCode = "" } = this.getData("default");

    if (couponCode === "") {
      error.couponCode = this.ucfirst("promo-code-helpertext");
    }

    this.setData({
      error,
    });
    if (Object.keys(error).length === 0) {
      if (guest) {
        Services.get("order").then(async ([orderService]) => {
          orderService
            .updateCoupon({
              couponCode,
            })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setOrder(order);
            })
            .catch((orderService) => {
              this.setData({
                "error.couponCode": this.ucfirst(orderService.getMessage()),
              });
            });
        });
      } else {
        Services.get("order,coupon").then(([orderService, couponService]) => {
          couponService
            .addCoupon(couponCode)
            .then((couponService) => {
              const couponId = couponService.getData("coupon");
              this.retrieveCoupons();
              return orderService.updateCoupon({
                couponId,
              });
            })
            .then((orderService) => {
              const order = orderService.getData("order");
              this.setOrder(order);
            })
            .catch((err) => {
              if (couponService.getData("_response").getMessage() === "error") {
                this.getComponents()
                  .findById("main-message")
                  .first()
                  .setData({
                    "error-message": this.trans("coupon-unavailable-message"),
                  });
              } else {
                console.log("error", err);
              }
            });
        });
      }
    }

    return this;
  }

  releaseCoupon() {
    Services.get("order").then(async ([orderService]) => {
      orderService
        .releaseCoupon()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  /* payment types section */

  updatePaymentType(id) {
    const { order } = this.getData("default", {}) || {};
    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updatePaymentType({
            paymentTypeId: id,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setData({ "default.order": order });
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  findPaymentTypeSelected(type) {
    var id = "";
    var paymentType = this.getData("default.order.paymentType", {}) || {};
    if (paymentType) {
      id = paymentType.id;
    }
    return id;
  }

  findPaymentTypeSelectedSlug() {
    var paymentType = this.getData("default.order.paymentType");
    return paymentType && paymentType.slug;
  }

  /* services section */

  updateService(id) {
    const { order } = this.getData("default", {}) || {};

    if (order) {
      Services.get("order").then(async ([orderService]) => {
        orderService
          .updateService({
            serviceId: id,
          })
          .then((orderService) => {
            const order = orderService.getData("order");
            this.setOrder(order);
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    }
  }

  /* complete order section */

  complete(e) {
    e.preventDefault();

    const { delivery_type = null } = this.getData("default.order", {});
    const shipping_address = this.getData("default.address", {
      ...this.default_address,
    });
    const user = this.getData("default.user", {});

    const error =
      delivery_type === "delivery"
        ? this.validateAddress(shipping_address)
        : this.validateUser(user);

    if (!error.shipping_address && !error.user) {
      if (delivery_type === "delivery") {
        this.setData({ "default.order.shipping_address": shipping_address });
      }
      Services.get("order,address").then(
        async ([orderService, addressService]) => {
          try {
            if (delivery_type === "delivery") {
              await addressService.updateAddress(
                shipping_address._id,
                shipping_address
              );
              await orderService.updateAddress({
                shippingAddressId: shipping_address._id,
                billingAddressId: shipping_address._id,
              });
            }

            await orderService.prepare({ data: user });

            const { slug = "" } = orderService.getData("order.paymentType", {});

            switch (slug) {
              case "epay-credit": {
                this.completeEpay();
                break;
              }
              default: {
                this.completeDefault();
              }
            }
          } catch (orderService) {
            this.checkOrderError(orderService);
          }
        }
      );
    } else {
      this.getPage().scrollToTop();
    }
  }

  completeDefault() {
    this.setData({ "default.preparing": true });

    Services.get("order").then(async ([orderService]) => {
      this.getApp().sendToGA4Order(orderService.getData("order"));
      await orderService.place();
      this.getPage().redirect("/");
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "message-duration": 5000,
          "success-message": `${this.trans(
            "complete-order-title"
          )} ${this.trans("complete-order-subtitle")}`,
        });
      this.setData({ "default.preparing": false });
    });

    return this;
  }

  completeEpay() {
    this.setData({ "default.preparing": true });

    Services.get("order").then(async ([orderService]) => {
      try {
        await orderService.preparePayment();

        const { creditData } =
          orderService.getData("order.paymentType", {}) || {};

        if (creditData) {
          this.epayRedirect(creditData);
        }
      } catch (e) {
        this.getComponents()
          .findById("main-message")
          .first()
          .setData({
            "error-message": this.trans(e.getMessage()),
          });
      }

      this.setData({ "default.preparing": false });
    });

    return this;
  }

  epayRedirect(data) {
    for (let i in data) {
      const element = document.querySelector(`[name="${i}"]`);

      if (element) {
        element.value = data[i];
      }
    }

    const form = document.getElementById("epay-redirection-form");

    if (form) {
      form.submit();
    }
  }

  verifyPayment() {
    const { epayerror } = this.getPage().getQuery();

    if (epayerror) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "error-message": this.trans("epay-error"),
        });
    }

    return this;
  }

  validateAddress(shipping_address) {
    const error = {
      shipping_address: this.getHelpers("validate").validate(shipping_address, {
        bell: [
          {
            rule: "required",
            message: this.ucfirst("bell-helpertext"),
          },
        ],
        floor: [
          {
            rule: "required",
            message: this.ucfirst("floor-helpertext"),
          },
        ],
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    return error;
  }

  validateUser(user) {
    const error = {
      user: this.getHelpers("validate").validate(user, {
        phone: [
          {
            rule: "required",
            message: this.ucfirst("phone-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("phone-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("phone-helpertext-2"),
            min: 10,
            max: 10,
          },
        ],
      }),
    };

    this.setData({
      error,
    });

    return error;
  }

  /* errors section */

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              "error-message": this.trans(type),
            });
          this.getPage().redirect("/");
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
