import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/bottom_cart";
import Services from "../../Services/Services";

export default class BottomCart extends Component {
  id = "main-appbar";
  group = "order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getOrder();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });

    return this;
  }

  toggleCartDrawer() {
    this.getComponents()
      .findById("cart-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }
}
