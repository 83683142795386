import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class User extends Service {
  //
  fetchOrder(id, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.user.order.one(account, repository, id).then((r) => {
        if (this.evaluate(r, "order")) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  contact(data, options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.contact(account, repository, data).then((r) => {
        const result = this.evaluate(r, "contact");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new User();
