import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useMediaQuery,
  Button,
  DialogActions,
  CircularProgress,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "../dialogs/address_form";

const SubmitBtn = styled(Button)(({ theme }) => ({
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "12px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#f05d32",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DeliveryType(props) {
  const { component } = props;

  const { open = false, addresses = null } = component.getData("default", {});

  const [hideAddressForm, setHideAddressForm] = React.useState(true);

  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Dialog
      onClose={() => {
        component.setData({ "default.open": false });
        setHideAddressForm(true);
      }}
      open={open}
      fullScreen={fullScreen}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: 0, sm: "25px" },
          minWidth: { xs: "initial", sm: "600px" },
        },
      }}
    >
      <MyDialogTitle
        onClose={() => {
          component.setData({ "default.open": false });
          setHideAddressForm(true);
        }}
      >
        {component.ucfirst("select-address-dialog-title")}
      </MyDialogTitle>
      <Box
        id="map"
        sx={{ width: "100%", height: { xs: "30%", sm: "200px" } }}
      ></Box>
      <DialogContent dividers sx={{ px: 2, py: 2 }}>
        <form onSubmit={(e) => component.addAddress(e, hideAddressForm)}>
          <Box>
            {!addresses ? (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <CircularProgress
                  sx={{
                    mt: 5,
                    color: "#f05d32",
                  }}
                />
              </Box>
            ) : (
              <>
                {hideAddressForm && addresses.length !== 0 ? (
                  <>
                    <Button
                      onClick={() => {
                        setHideAddressForm(false);
                        component.showAddressForm("shipping_address");
                      }}
                      sx={{
                        float: "right",
                        padding: "12px 14px",
                        borderRadius: "6px",
                        border: "1px solid #000",
                        color: "#000",
                        textTransform: "none",
                        "&:hover": {
                          bgcolor: "#fff",
                        },
                      }}
                      startIcon={
                        <i className="fak fa-light-location-dot-circle-plus" />
                      }
                    >
                      {component.trans("add-new-address-btn")}
                    </Button>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <InputLabel>Select Address</InputLabel>
                      <Select
                        name="shipping_address"
                        id="shipping_address"
                        value={component.findAddressSelected("shipping")}
                        label="Select Address"
                      >
                        {addresses.map((address) => {
                          const {
                            id,
                            street = "",
                            street_number = "",
                            city = "",
                            state = "",
                            postal_code = "",
                          } = address;
                          return (
                            <MenuItem
                              key={id}
                              value={id}
                              sx={{ display: "flex", flexDirection: "column" }}
                              onClick={() => {
                                component.selectAddress(id, "shipping");
                              }}
                            >
                              <Box sx={{ width: "100%" }}>
                                <b>
                                  {`${component.ucfirst(
                                    street
                                  )} ${component.ucfirst(street_number)}`}
                                </b>
                              </Box>
                              <Box sx={{ width: "100%" }}>
                                {`${component.ucfirst(
                                  city
                                )}, ${component.ucfirst(
                                  state
                                )} ${component.ucfirst(postal_code)}`}
                              </Box>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </>
                ) : (
                  <AddressForm
                    {...{
                      ...props,
                      ...{
                        type: "shipping_address",
                        fullAddress: false,
                      },
                    }}
                  />
                )}
              </>
            )}
          </Box>
          <button type="submit" hidden></button>
        </form>
      </DialogContent>
      <DialogActions sx={{ px: 1 }}>
        <SubmitBtn
          fullWidth
          sx={{ mx: 2 }}
          onClick={(e) => component.addAddress(e, hideAddressForm)}
        >
          {component.ucfirst("address-submit-btn")}
        </SubmitBtn>
      </DialogActions>
    </Dialog>
  );
}
