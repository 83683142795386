import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  TextField,
  Typography,
  Link,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Alert,
  Autocomplete,
  Grid,
} from "@mui/material";

const MyCouponsBtn = styled(Link)(({ theme }) => ({
  float: "right",
  color: "#000",
  fontSize: "15px",
  fontWeight: "400",
  cursor: "pointer",
  borderBottom: "1px solid #000",
  pointerEvents: "auto",
  height: 25,
  "&:hover": {
    borderBottom: "1px solid transparent",
  },
}));

const PromoCode = styled(Alert)(({ theme }) => ({
  marginTop: "8px",
  border: "1px solid #f05d32",
  backgroundColor: "#f05d32",
  position: "relative",
  textAlign: "center",
  alignItems: "center",
  borderRadius: "12px",
  color: "#fff",
  fontSize: 20,
  fontWeight: 400,
  "& .MuiAlert-action": {
    padding: 0,
  },
}));

const CouponAutocomplete = styled(Autocomplete)(({ theme }) => ({
  marginTop: "8px",
  backgroundColor: "#fff",
  borderRadius: "12px",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const CouponOptions = styled(Box)(({ theme }) => ({
  color: "#000",
  padding: "6px 16px",
  fontWeight: "450",
  minHeight: "auto !important",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  cursor: "pointer",
  "&:hover, &:focus": {
    color: "#7d7d7d",
  },
  "& > i": {
    marginRight: "8px",
  },
}));

const NewCouponField = styled(FormControl)(({ theme }) => ({
  margin: "8px 0px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

export default function CouponsSection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null, guest = false } = component.getData("default", {});

  const coupons = component.getData("default.coupons", []);

  const [couponAutocomplete, showCouponAutocomplete] = React.useState(true);

  return (
    <>
      {order && (
        <Box
          sx={{
            px: { xs: 2, md: 3 },
            py: 2,
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "20px", sm: "24px", md: "28px" },
              lineHeight: 1,
              mb: 2,
            }}
          >
            {component.ucfirst("checkout-title-2")}
          </Typography>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
              {!devHelper.getObjectValue(order, "coupon") ? (
                <>
                  {!guest && coupons.length > 1 && couponAutocomplete ? (
                    <>
                      {component.ucfirst("checkout-subtitle-2-2")}
                      <CouponAutocomplete
                        id="mui-autocomplete"
                        fullWidth={true}
                        autoComplete={false}
                        options={coupons}
                        autoHighlight
                        getOptionLabel={(option) => option.name}
                        renderOption={(props, option) => {
                          return option.slug !== "add" ? (
                            <CouponOptions
                              key={option._id}
                              onClick={() =>
                                component.activateCoupon(option._id)
                              }
                            >
                              <i className="fa-light fa-ticket" />
                              {option.name}
                            </CouponOptions>
                          ) : (
                            <CouponOptions
                              id={option.slug}
                              key={option.slug}
                              onClick={() => showCouponAutocomplete(false)}
                              sx={{ textAlign: "center" }}
                            >
                              <i className="fa-light fa-plus"></i>
                              {option.name}
                            </CouponOptions>
                          );
                        }}
                        renderInput={(params) => {
                          return (
                            <TextField
                              {...params}
                              label={component.trans("promo-code")}
                              variant="outlined"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: "new-password",
                              }}
                            />
                          );
                        }}
                      />
                    </>
                  ) : (
                    <Box>
                      {component.ucfirst("checkout-subtitle-2-1")}
                      <form onSubmit={(e) => component.updateCouponByCode(e)}>
                        <NewCouponField
                          variant="outlined"
                          fullWidth
                          name="couponCode"
                          type="text"
                          id="couponCode"
                          error={component.dataExists("error.couponCode")}
                        >
                          <InputLabel>
                            {component.trans("promo-code")}
                          </InputLabel>
                          <OutlinedInput
                            placeholder={component.trans("promo-holder")}
                            value={component.getData("default.couponCode", "")}
                            onChange={(e) => {
                              component.setData({
                                "default.couponCode": e.target.value,
                              });
                            }}
                            label={component.trans("promo-code")}
                            endAdornment={
                              <InputAdornment position="end">
                                <IconButton
                                  edge="end"
                                  disableRipple
                                  type="submit"
                                  sx={{
                                    color: "#f05d32",
                                    fontSize: "30px",
                                    "&:hover, &:focus": {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <i className="fa-solid fa-circle-chevron-right" />
                                </IconButton>
                              </InputAdornment>
                            }
                          />
                          {component.dataExists("error.couponCode") && (
                            <FormHelperText
                              error
                              style={{ margin: "3px 14px 0" }}
                            >
                              {component.getData("error.couponCode")}
                            </FormHelperText>
                          )}
                        </NewCouponField>
                      </form>

                      {!guest && coupons.length > 1 && (
                        <Box
                          sx={{
                            marginBottom: "40px",
                          }}
                        >
                          <MyCouponsBtn
                            underline="none"
                            sx={{
                              float: "left !important",
                            }}
                            onClick={() => showCouponAutocomplete(true)}
                          >
                            {component.ucfirst("my-coupons")}
                          </MyCouponsBtn>
                        </Box>
                      )}
                    </Box>
                  )}
                </>
              ) : (
                <>
                  <PromoCode
                    onClose={() => {
                      component.releaseCoupon();
                      showCouponAutocomplete(true);
                    }}
                    elevation={0}
                    variant="filled"
                    icon={false}
                  >
                    {devHelper.getObjectValue(order, "coupon.name")}
                  </PromoCode>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
