import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/terms_and_conditions/terms_and_conditions";
import Services from "../../Services/Services";

export default class TermsAndConditions extends Component {
  id = "terms-and-conditions";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setSection();

    this.getFooterLinks();
  }

  async setSection() {
    const section = this.getPage().getParam("section", "");

    //Set window title
    const account = this.getHelpers("env").getDefaultAccount();
    const repository = this.getHelpers("env").getDefaultRepository();
    const translation =
      (await this.getHelpers("translate").scope({
        account: account,
        repository: repository,
        entity: "item",
        entity_slug: section,
        scope: "default",
        slug: "name",
      })) || [];

    const name = (
      translation.find((tran) => tran.slug === "name") || {
        translation: "",
      }
    ).translation;

    this.getApp().updateWindowTitle(
      `${this.ucfirst(name)} | ${this.ucfirst(
        "terms-and-conditions"
      )} | ${this.ucfirst("window-title")}`
    );
    this.setData({
      "default.section": section,
    });
  }

  getFooterLinks() {
    Services.get("hook").then(([service]) => {
      service
        .exec("statics", {
          params: { scope: "terms-and-conditions" },
        })
        .then((response) => {
          const links = response.getData() || {};
          this.setData({
            "default.links": links,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }
}
