import React from "react";
import {
  Toolbar,
  AppBar,
  Link,
  Button,
  Container,
  CardMedia,
} from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

export default function CheckoutNavbar(props) {
  const { component } = props;
  const theme = useTheme();
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <>
      <AppBar
        position="relative"
        elevation={0}
        sx={{
          backgroundColor: "#fff",
        }}
      >
        <Toolbar
          disableGutters
          sx={{ minHeight: "0px !important", backgroundColor: "#f05d32" }}
        >
          <Container
            maxWidth="xl"
            disableGutters
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              px: {
                xs: "4px",
                md: 6,
              },
              height: {
                xs: theme.appbar_height.mobile,
                md: theme.appbar_height.desktop,
              },
            }}
          >
            <Button
              sx={{
                color: "#fff",
                fontSize: { xs: "25px", md: "30px" },
                p: 0,
                "&:hover": {
                  bgcolor: "#f05d32",
                },
              }}
              disableRipple
              onClick={() => {
                component.getPage().goBack();
              }}
            >
              <i className="fa-light fa-circle-chevron-left"></i>
            </Button>
            <Link
              component={RouterLink}
              to="/"
              sx={{
                textDecoration: "none",
                height: { xs: "16px", sm: "20px", md: "24px" },
              }}
            >
              <CardMedia
                component="img"
                image={`${filesUrl}/assets/images/logo.png?v=${img_version}`}
                sx={{
                  width: { xs: "118px", sm: "140px", md: "180px" },
                }}
                alt="il Palatino Home"
                title="il Palatino Home"
              />
            </Link>
            <Button
              sx={{
                visibility: "hidden",
                color: "#fff",
                fontSize: { xs: "30px", md: "30px" },
                p: 0,
                "&:hover": {
                  bgcolor: "#f05d32",
                },
              }}
              disableRipple
              onClick={() => {
                component.getPage().goBack();
              }}
            >
              <i className="fa-light fa-circle-chevron-left"></i>
            </Button>
          </Container>
        </Toolbar>
      </AppBar>
      <Toolbar id="back-to-top-anchor" style={{ minHeight: 1 }} />
    </>
  );
}
