import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/home";

export default class Home extends Page {
  title = "home";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Home")} | ${this.trans("window-title")}`
    );
    this.scrollToTop();
  }
}
