import React from "react";
import { styled } from "@mui/material/styles";
import {
  Grid,
  TextField,
  Typography,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  marginTop: "8px",
  marginBottom: "8px",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const Info = styled(Typography)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  fontSize: "16px",
  margin: theme.spacing(1, 0),
  fontWeight: 500,
  "& i": {
    marginRight: theme.spacing(1),
  },
}));

const MyFormControl = styled(FormControl)(({ theme }) => ({
  margin: "8px 0px",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

export default function Checkout(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const {
    order = null,
    cutlery = null,
    hours,
  } = component.getData("default", {});

  const delivery_type = devHelper.getObjectValue(order, "delivery_type");

  var type = "shipping_address";

  const changeValue = (value, attr) => {
    component.setData({
      ["default.order." + type + "." + attr]: value,
      ["default.address." + attr]: value,
    });
    component.deleteData("error." + type + "." + attr);
  };

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: "12px",
            backgroundColor: "#fff",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "20px", sm: "24px", md: "28px" },
              lineHeight: 1,
              mb: 2,
            }}
          >
            {component.ucfirst("checkout-title-1")}
          </Typography>
          <Box sx={{ my: 2 }}>
            <Info sx={{ mb: 2 }}>
              <span style={{ fontWeight: 400 }}>{`${component.ucfirst(
                "delivery-type"
              )}: `}</span>
              {`${component.ucfirst(delivery_type)}`}
            </Info>

            {delivery_type === "delivery" ? (
              <Info>
                <i className="fa-regular fa-location-dot" />
                {devHelper.getObjectValue(order, "shipping_address.address")}
              </Info>
            ) : (
              <Box>
                <Info>
                  <i className="fa-regular fa-location-dot" />
                  {component.trans(
                    `${devHelper.getObjectValue(order, "spot.slug")}-address`
                  )}
                </Info>
                <Info>
                  <i className="fa-regular fa-phone" />
                  {component.trans(
                    `${devHelper.getObjectValue(order, "spot.phone")}`
                  )}
                </Info>
              </Box>
            )}
          </Box>
          {hours.length && (
            <Box sx={{ maxWidth: 110 }}>
              <MyFormControl fullWidth>
                <InputLabel>
                  <i className="fa-light fa-clock" />{" "}
                  {component.ucfirst("time")}
                </InputLabel>
                <Select
                  value={devHelper.getObjectValue(order, "time", "now")}
                  label={
                    <>
                      <i className="fa-light fa-clock" />{" "}
                      {component.ucfirst("time")}
                    </>
                  }
                  onChange={(e) => {
                    component.updateTime(e.target.value);
                  }}
                >
                  <MenuItem value="now">{component.ucfirst("now")}</MenuItem>
                  {hours.map((hour, index) => (
                    <MenuItem
                      key={`hour-${index}`}
                      value={`${hour.hour}:${hour.min}`}
                    >
                      {`${hour.hour % 12}:${hour.min} `}
                      {component.trans(hour.hour < 12 ? "am" : "pm")}
                    </MenuItem>
                  ))}
                </Select>
              </MyFormControl>
            </Box>
          )}
          {delivery_type === "delivery" ? (
            <Grid container justifycontent="center" spacing={1}>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("phone-textfield")}
                  name="phone"
                  helperText={
                    component.getData("error." + type + ".phone", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.address.phone", "")}
                  onChange={(e) => changeValue(e.target.value, "phone")}
                  error={component.dataExists("error." + type + ".phone")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("bell-textfield")}
                  name="bell"
                  helperText={
                    component.getData("error." + type + ".bell", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.address.bell", "")}
                  onChange={(e) => changeValue(e.target.value, "bell")}
                  error={component.dataExists("error." + type + ".bell")}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("floor-textfield")}
                  name="floor"
                  helperText={
                    component.getData("error." + type + ".floor", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.address.floor", "")}
                  onChange={(e) => changeValue(e.target.value, "floor")}
                  error={component.dataExists("error." + type + ".floor")}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("notes-textfield")}
                  name="notes"
                  helperText={
                    component.getData("error." + type + ".notes", [
                      { message: "" },
                    ])[0].message
                  }
                  value={component.getData("default.address.notes", "")}
                  onChange={(e) => changeValue(e.target.value, "notes")}
                  error={component.dataExists("error." + type + ".notes")}
                />
              </Grid>
            </Grid>
          ) : (
            <Grid container justifycontent="center" spacing={1}>
              <Grid item xs={12}>
                <MyTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  label={component.ucfirst("phone-textfield")}
                  name="phone"
                  helperText={
                    component.getData("error.user.phone", [{ message: "" }])[0]
                      .message
                  }
                  value={component.getData("default.user.phone", "")}
                  onChange={(e) =>
                    component.setData({ "default.user.phone": e.target.value })
                  }
                  error={component.dataExists("error.user.phone")}
                />
              </Grid>
            </Grid>
          )}
          {cutlery !== null && (
            <FormControlLabel
              sx={{
                mr: 0,
                "&	.MuiFormControlLabel-label": {
                  fontSize: 18,
                },
              }}
              key={devHelper.getObjectValue(cutlery, "slug")}
              value={devHelper.getObjectValue(cutlery, "id")}
              control={
                <Checkbox
                  sx={{
                    color: "#f05d32",
                    "&.Mui-checked": {
                      color: "#f05d32",
                    },
                    "& .MuiSvgIcon-root": { fontSize: { xs: 20, sm: 20 } },
                  }}
                  checked={devHelper.getObjectValue(cutlery, "selected")}
                  onChange={(e) => {
                    component.updateService(
                      devHelper.getObjectValue(cutlery, "id")
                    );
                  }}
                />
              }
              label={
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: 16,
                    fontWeight: 500,
                    "& div": {
                      textAlign: "center",
                      display: "inline-flex",
                    },
                    "& i": {
                      minWidth: 30,
                      ml: 1,
                      fontSize: 20,
                    },
                  }}
                >
                  {component.ucfirst(devHelper.getObjectValue(cutlery, "slug"))}
                  <Box>
                    <i className="fa-solid fa-utensils" />
                  </Box>
                </Box>
              }
            />
          )}
        </Paper>
      )}
    </>
  );
}
