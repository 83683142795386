import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogContent,
  Button,
  useMediaQuery,
  CardMedia,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export default function Address(props) {
  const { component } = props;
  const { open = false, ready = false } = component.getData("default", {});
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Dialog
      onClose={() => {
        component.toggle(false);
      }}
      open={open}
      // open={true}
      fullScreen={fullScreen}
      maxWidth="xs"
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: 0, md: "10px" },
          bgcolor: "#f05d32",
          textAlign: "center",
          display: ready ? "block" : "none",
        },
      }}
    >
      <DialogContent
        dividers
        sx={{ p: 0, position: "relative", border: "none" }}
      >
        <IconButton
          aria-label="close"
          onClick={() => {
            component.toggle(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
        <CardMedia
          sx={{
            maxWidth: "100%",
          }}
          component="img"
          alt={"offer image"}
          image={`${filesUrl}/assets/images/offer-1.jpg?v=${img_version}`}
          onError={() => {
            component.setData({ "default.ready": true });
          }}
          onLoad={() => {
            component.setData({ "default.ready": true });
          }}
        />
        <Button
          sx={{
            color: "#fff",
            border: "1px solid #fff",
            borderRadius: 50,
            fontSize: { xs: "14px", md: "16px" },
            px: { xs: 1, md: 2 },
            py: { xs: "4px", md: "4px" },
            my: "auto",
            "&:hover": {
              backgroundColor: "#fff",
              color: "#f05d32",
            },
            mb: 2,
            cursor: "pointer",
          }}
          disableRipple
          onClick={() => {
            component.toggle(false);
            component
              .getComponents()
              .findById("login-register-dialog")
              .first()
              .setData({
                "default.open": true,
              });
          }}
        >
          {component.ucfirst("register-btn")}
        </Button>
      </DialogContent>
    </Dialog>
  );
}
