import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/drawers/cart_drawer";
import Services from "../../Services/Services";

export default class CartDrawer extends Component {
  id = "cart-drawer";
  group = "order";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();

    this.setIngredientTypes();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order", {});
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  setOrder() {
    return this.getData("default.order", {});
  }

  setIngredientTypes() {
    Services.get("hook").then(([service]) => {
      service.exec("ingredient-types").then((response) => {
        const data = response.getData() || [];
        this.setData({
          "default.ingredient_types": data,
        });
      });
    });

    return this;
  }

  toggle() {
    this.toggleData("default.opened", {
      defaultValue: true,
    });
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.setData({ "default.order": {} });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
