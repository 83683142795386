import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Box,
  CardMedia,
  Grid,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#f05d32",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 5,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DeliveryType(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const open = component.getData("default.open", false);

  const sliders = [
    {
      slug: "wolt",
      name: "Wolt",
      url: "https://wolt.com/el/grc/athens/restaurant/il-palatino-sevastoupoleos",
    },
    {
      slug: "box",
      name: "Box",
      url: "https://box.gr/delivery/ampelokipoi/il-palatino-ampelokipoi",
    },
    {
      slug: "efood",
      name: "Efood",
      url: "https://www.e-food.gr/delivery/athina/il-palatino",
    },
  ];

  return (
    <Dialog
      onClose={() => {
        component.setData({ "default.open": false });
      }}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: "25px",
          minWidth: { xs: "95%", sm: "500px" },
        },
      }}
    >
      <MyDialogTitle
        onClose={() => {
          component.setData({ "default.open": false });
        }}
      >
        {component.ucfirst("delivery-type-dialog-title")}
      </MyDialogTitle>
      <DialogContent dividers sx={{ p: { xs: 1, sm: 2 } }}>
        <Typography
          align="center"
          sx={{
            fontSize: { xs: 16, sm: 18 },
            lineHeight: 1,
            fontWeight: 600,
            color: "#000",
          }}
        >
          {component.ucfirst("takeaway-title")}
        </Typography>
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
          <Grid item xs={3}>
            <Link
              onClick={() => component.updateDeliveryType("takeaway")}
              sx={{ textDecoration: "none", cursor: "pointer" }}
            >
              <Box
                sx={{
                  width: "100%",
                  borderRadius: 50,
                  backgroundColor: "transparent",
                  p: { xs: 0, sm: 1 },
                }}
              >
                <CardMedia
                  sx={{
                    width: "100%",
                    margin: "0 auto",
                  }}
                  component="img"
                  image={`${filesUrl}/assets/images/takeaway.png?v=${img_version}`}
                  alt="takeaway image"
                />
              </Box>
              <Typography
                align="center"
                sx={{
                  fontSize: { xs: 12, sm: 16 },
                  lineHeight: 1,
                  fontWeight: 600,
                  color: "#000",
                  mt: -1,
                }}
              >
                {component.ucfirst("footer-title-1")}
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Box
          component="hr"
          sx={{
            border: "1px solid #f05d32",
            my: 2,
            width: "50%",
            mx: "auto",
          }}
        ></Box>
        <Typography
          align="center"
          sx={{
            fontSize: { xs: 16, sm: 18 },
            lineHeight: 1,
            fontWeight: 600,
            color: "#000",
          }}
        >
          {component.ucfirst("delivery-title")}
        </Typography>
        <Grid container spacing={0} sx={{ justifyContent: "center" }}>
          {sliders.map((slider) => {
            const { slug, name, url } = slider;

            return (
              <Grid key={slug} item xs={3}>
                <Link
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ textDecoration: "none" }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      borderRadius: 50,
                      backgroundColor: "transparent",
                      p: { xs: 0, sm: 1 },
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: "100%",
                        margin: "0 auto",
                      }}
                      component="img"
                      image={`${filesUrl}/assets/images/${slug}.png?v=${img_version}`}
                      alt={`${name} image`}
                    />
                  </Box>
                  <Typography
                    align="center"
                    sx={{
                      fontSize: { xs: 12, sm: 16 },
                      lineHeight: 1,
                      fontWeight: 600,
                      color: "#000",
                    }}
                  >
                    {component.ucfirst(name)}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
