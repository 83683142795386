import React from "react";
import Box from "@mui/material/Box";
import BottomCart from "../../../../Components/Widgets/BottomCart";
import Footer from "../../widgets/footer/footer";
import NavBar from "../../../../Components/Widgets/Navbar";
import CartDrawer from "../../../../Components/Widgets/CartDrawer";
import Coupons from "../../../../Components/Widgets/User/Coupons";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <NavBar {...props} />
      <CartDrawer {...props} />
      <Coupons {...props} />
      <Footer {...props} />
      <BottomCart {...props} />
    </Box>
  );
};

export default ExportDefault;
