import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
} from "@mui/material";

export default function PoductListSkeletonView(props) {
  const { index } = props;

  return (
    <ListItem
      disablePadding
      sx={{
        borderBottom: index === 4 ? "none" : "1px solid #f7f7f7",
        py: 2,
      }}
    >
      <ListItemButton
        sx={{
          alignItems: "flex-start",
          p: 0,
          "&:hover": { backgroundColor: "transparent" },
        }}
        disableRipple
      >
        <ListItemText
          sx={{ m: 0 }}
          primary={
            <Skeleton variant="text" sx={{ fontSize: 16 }} width="50%" />
          }
          secondary={
            <>
              <Skeleton variant="text" sx={{ fontSize: 14 }} width="80%" />
              <Skeleton
                variant="text"
                sx={{ fontSize: 24, mt: 1 }}
                width={60}
              />
            </>
          }
          secondaryTypographyProps={{ sx: { mt: 1 }, component: "div" }}
        />
        <Skeleton
          variant="text"
          height={110}
          width={110}
          sx={{ borderRadius: "12px", transform: "none" }}
        />
      </ListItemButton>
    </ListItem>
  );
}
