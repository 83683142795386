import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/language_select";
import Services from "../../Services/Services";

export default class LanguageSelect extends Component {
  id = "language-select";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    Services.get("language").then(([languageService]) => {
      languageService.getLanguages().then((languageService) => {
        this.setData({
          "default.languages": languageService.getData("languages"),
        });
      });
    });

    this.setData({ "default.lang": this.getLang() });
  }

  selectLang(lang) {
    this.getHelpers("language").setLang(lang);
    this.setData({ "default.lang": lang });

    this.getPage().reload();
  }

  getLang() {
    return this.getHelpers("language").getLang("en");
  }
}
