import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/select_spot_dialog";
import Services from "../../../Services/Services";

export default class SelectSpotDialog extends Component {
  id = "select-spot-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });

    this.setSpots();
  }

  setSpots() {
    Services.get("hook").then(([service]) => {
      service.exec("spots").then((response) => {
        const spots = response.getData() || [];
        this.setData({
          "default.spots": spots,
        });
      });
    });

    return this;
  }

  updateSpot(spotId) {
    Services.get("order").then(async ([orderService]) => {
      orderService
        .updateSpot({
          spotId,
        })
        .then((orderService) => {
          const order = orderService.getOrder();

          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });
          this.setData({
            "default.open": false,
          });
          this.getComponents().findById("products").first().fetchProducts();
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
    // }
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              "error-message": this.trans(type),
            });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
