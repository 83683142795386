import React from "react";
import { styled } from "@mui/material/styles";
import {
  CardMedia,
  Typography,
  Tabs,
  Tab,
  Box,
  useMediaQuery,
} from "@mui/material";
import { isMobile } from "react-device-detect";
import { useTheme } from "@mui/material/styles";

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    padding: "0px 8px 8px",
    margin: "0px 4px",
    "&.Mui-selected": {
      color: "#fff",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

export default function MySlider(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const theme = useTheme();
  const img_version = component.getHelpers("value").getValue("images-version");

  const sliderHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.slider_height.desktop
    : theme.slider_height.mobile;

  const sliders = component.getData("default.sliders", []);
  const value = component.getData("default.slider_index", -1);

  const handleChange = (event, newValue) => {
    component.changeSlide(newValue);
  };

  const scrollToEl = (slide_to) => {
    const el = document.getElementById(slide_to);
    if (el) {
      window.scrollTo({
        top:
          el.getBoundingClientRect().top +
          window.pageYOffset -
          sliderHeight.replace("px", "") * 1,
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: { xs: "left", lg: "center" },
      }}
    >
      {sliders.length !== 0 && (
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons={!isMobile}
          sx={{
            padding: "4px 0px",
            "& .MuiTabs-indicator": {
              backgroundColor: "#f05d32 !important",
              height: "2px",
            },
            "& .MuiTabScrollButton-root": {
              color: "#f05d32",
            },
            "& .MuiSvgIcon-fontSizeSmall": {
              fontSize: "40px",
            },
          }}
        >
          {sliders.map((slider, index) => (
            <StyledTab
              key={devHelper.getObjectValue(slider, "_values.slug")}
              disableRipple
              label={
                <Box
                  onClick={() =>
                    scrollToEl(
                      devHelper.getObjectValue(slider, "_values.slide_to")
                    )
                  }
                >
                  <Box
                    sx={{
                      width: { xs: "50px", md: "70px" },
                      height: { xs: "50px", md: "70px" },
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      borderRadius: 50,
                      border: "2px solid #f05d32",
                      backgroundColor: index === 0 ? "#f05d32" : "transparent",
                      mx: "auto",
                      mb: { xs: "4px", md: 1 },
                      pointer: "cursor",
                    }}
                  >
                    <CardMedia
                      sx={{
                        width: { xs: "30px", md: "45px" },
                        height: { xs: "30px", md: "45px" },
                        margin: "0 auto",
                      }}
                      component="img"
                      image={`${filesUrl}/assets/images/sliders/${devHelper.getObjectValue(
                        slider,
                        "_values.slug"
                      )}.png?v=${img_version}`}
                      alt={devHelper.getObjectValue(slider, "_values.slug")}
                    />
                  </Box>
                  <Typography
                    component="h1"
                    sx={{
                      fontSize: { xs: "12px", md: "14px" },
                      textTransform: "uppercase",
                      lineHeight: 1,
                      fontWeight: 600,
                      margin: "0 auto",
                      letterSpacing: "normal",
                      display: "block",
                      color: "#000",
                      maxWidth: 180,
                    }}
                  >
                    {component.trans(
                      devHelper.getObjectValue(slider, "_values.slug")
                    )}
                  </Typography>
                </Box>
              }
            ></StyledTab>
          ))}
        </Tabs>
      )}
    </Box>
  );
}
