import React from "react";
import Box from "@mui/material/Box";
import BottomCart from "../../../Components/Widgets/BottomCart";
import Footer from "../widgets/footer/footer";
import NavBar from "../../../Components/Widgets/Navbar";
import CartDrawer from "../../../Components/Widgets/CartDrawer";
import Products from "../../../Components/Widgets/Products";
import ResetPasswordDialog from "../../../Components/Widgets/Dialogs/ResetPasswordDialog";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        bgcolor: "#faf7f5",
      }}
    >
      <NavBar
        {...{
          ...props,
          ...{
            slider: true,
          },
        }}
      />
      <ResetPasswordDialog {...props} />
      <CartDrawer {...props} />
      <Products {...props} />
      <Footer {...props} />
      <BottomCart {...props} />
    </Box>
  );
};

export default ExportDefault;
