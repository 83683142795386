import React from "react";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Toolbar,
  Menu,
  Button,
  MenuItem,
  Badge,
  Link,
  Avatar,
  CardMedia,
  Container,
  // Switch,
} from "@mui/material";
import LanguageSelect from "../../../../Components/Widgets/LanguageSelect";
import { useTheme } from "@mui/material/styles";

const CouponBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
    background: "#f05d32",
  },
}));

const CouponBadge2 = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -13,
    top: 12,
    padding: "0 4px",
    background: "#f05d32",
  },
}));

const IconsButton = styled(Button)(({ theme }) => ({
  color: "#fff",
  fontSize: "25px",
  backgroundColor: "transparent",
  marginRight: 5,
  paddingLeft: 0,
  paddingRight: 0,
  minWidth: 40,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": { color: "#f05d32", backgroundColor: "#fff" },
}));

const MyMenuItem = styled(MenuItem)(({ theme }) => ({
  color: "#7d7d7d",
  backgroundColor: "transparent",
  "&:hover": {
    backgroundColor: "transparent",
    color: "#f05d32",
  },
}));

// const MySwitch = styled((props) => (
//   <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
// ))(({ theme }) => ({
//   width: "112px",
//   height: "30px",
//   padding: 0,
//   "& .MuiSwitch-switchBase": {
//     "& .MuiSwitch-input": {
//       width: "900%",
//       left: "-400%",
//     },
//     padding: 0,
//     margin: "4px",
//     transitionDuration: "300ms",
//     "& + .MuiSwitch-track": {
//       "&:after": { content: "'Delivery'", right: "16px" },
//       "&:before": { content: "''" },
//     },
//     "&.Mui-checked": {
//       transform: "translateX(82px)",
//       color: "#fff",
//       "& + .MuiSwitch-track": {
//         backgroundColor: "transparent",
//         border: "1px solid #fff",
//         opacity: 1,
//         "&:before": {
//           content: "'Take Away'",
//           left: "8px",
//         },
//         "&:after": { content: "''" },
//       },
//       "&.Mui-disabled + .MuiSwitch-track": {
//         // opacity: 0.5,
//       },
//       "& .MuiSwitch-track": {},
//     },
//     "&.Mui-focusVisible .MuiSwitch-thumb": {
//       color: "#ffc61a",
//       border: "6px solid #fff",
//     },
//     "&.Mui-disabled .MuiSwitch-thumb": {
//       color:
//         theme.palette.mode === "light"
//           ? theme.palette.grey[100]
//           : theme.palette.grey[600],
//     },
//     "&.Mui-disabled + .MuiSwitch-track": {
//       opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
//     },
//   },
//   "& .MuiSwitch-thumb": {
//     boxSizing: "border-box",
//     width: "22px",
//     height: "22px",
//   },
//   "& .MuiSwitch-track": {
//     borderRadius: 30 / 2,
//     border: "1px solid #fff",
//     backgroundColor: "transparent",
//     opacity: 1,
//     transition: theme.transitions.create(["background-color"], {
//       duration: 500,
//     }),
//     "&:after, &:before": {
//       color: "white",
//       fontSize: "15px",
//       fontWeight: 500,
//       position: "absolute",
//       top: "50%",
//       transform: "translateY(-50%)",
//     },
//   },
// }));

export default function MainAppbar(props) {
  const { component } = props;
  const theme = useTheme();
  const devHelper = component.getHelpers("dev");
  const coupons = component.getData("default.coupons", []);

  const isAuth = component.isAuthenticated();
  const username = component
    .getAuth()
    .getUserAttribute("name")
    .split(" ")
    .map((word) => word.charAt(0))
    .splice(0, 2)
    .join("");
  const order = component.getData("default.order", null);
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");
  const deliveryType = devHelper.getObjectValue(order, "delivery_type");
  const isTakeaway = deliveryType === "takeaway";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Toolbar
      disableGutters
      sx={{ minHeight: "0px !important", backgroundColor: "#f05d32" }}
    >
      <Container
        maxWidth="xl"
        disableGutters
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: {
            xs: 0,
            sm: 2,
            md: 3,
            lg: 6,
          },
          pl: {
            xs: "2px",
            sm: 2,
            md: 3,
            lg: 6,
          },
          height: {
            xs: theme.appbar_height.mobile,
            md: theme.appbar_height.desktop,
          },
        }}
      >
        {isAuth && deliveryType && (
          <Box
            sx={{
              display: { xs: "flex", sm: "none" },
              alignItems: "center",
            }}
          >
            {/* <MySwitch
              checked={
                devHelper.getObjectValue(order, "delivery_type") === "takeaway"
              }
              onChange={() => {
                component.updateDeliveryType(
                  devHelper.getObjectValue(order, "delivery_type")
                );
              }}
              sx={{ my: 1, display: "inline-flex" }}
            /> */}
            <Box
              component="a"
              sx={{
                textDecoration: "underline",
                ml: 1,
                fontSize: 20,
                cursor: "pointer",
              }}
              onClick={() => {
                component
                  .getComponents()
                  .findById(
                    isTakeaway
                      ? "delivery-type-dialog"
                      : "select-address-dialog"
                  )
                  .forEach((comp) => {
                    comp.setData({
                      "default.open": true,
                    });
                    if (!isTakeaway) {
                      setTimeout(() => {
                        comp.retrieveAddresses();
                        comp.initGoogleAutocomplete("shipping_address");
                      }, 500);
                    }
                  });
              }}
              aria-label={isTakeaway ? "Change store" : "Change address"}
            >
              {isTakeaway ? (
                <i className="fa-light fa-store" />
              ) : (
                <i className="fa-light fa-location-dot" />
              )}
            </Box>
          </Box>
        )}
        <Link
          component={RouterLink}
          to="/"
          sx={{
            textDecoration: "none",
            display: {
              xs: isAuth && deliveryType ? "none" : "block",
              sm: "block",
            },
          }}
        >
          <CardMedia
            component="img"
            image={`${filesUrl}/assets/images/logo.png?v=${img_version}`}
            sx={{
              width: { xs: "118px", sm: "140px", md: "180px" },
            }}
            alt="il Palatino Home"
            title="il Palatino Home"
          />
        </Link>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {isAuth && deliveryType && (
            <Box
              sx={{
                display: { xs: "none", sm: "flex" },
                alignItems: "center",
              }}
            >
              {/* <MySwitch
                checked={
                  devHelper.getObjectValue(order, "delivery_type") ===
                  "takeaway"
                }
                onChange={() => {
                  component.updateDeliveryType(
                    devHelper.getObjectValue(order, "delivery_type")
                  );
                }}
                sx={{ m: 1, display: "inline-flex" }}
              /> */}
              <Box
                component="a"
                sx={{
                  textDecoration: "underline",
                  mx: 2,
                  mr: 3,
                  fontSize: 20,
                  cursor: "pointer",
                }}
                onClick={() => {
                  component
                    .getComponents()
                    .findById(
                      isTakeaway
                        ? "delivery-type-dialog"
                        : "select-address-dialog"
                    )
                    .forEach((comp) => {
                      comp.setData({
                        "default.open": true,
                      });
                      if (!isTakeaway) {
                        setTimeout(() => {
                          comp.retrieveAddresses();
                          comp.initGoogleAutocomplete("shipping_address");
                        }, 500);
                      }
                    });
                }}
                aria-label={isTakeaway ? "Change store" : "Change address"}
              >
                {isTakeaway ? (
                  <i className="fa-light fa-store" />
                ) : (
                  <i className="fa-light fa-location-dot" />
                )}
              </Box>
            </Box>
          )}
          <LanguageSelect {...props} />
          {!isAuth ? (
            <Button
              sx={{
                color: "#fff",
                border: "1px solid #fff",
                borderRadius: 50,
                fontSize: { xs: "14px", md: "16px" },
                px: { xs: 1, md: 2 },
                py: { xs: "4px", md: "4px" },
                my: "auto",
                "&:hover": {
                  backgroundColor: "#fff",
                  color: "#f05d32",
                },
              }}
              disableRipple
              onClick={() =>
                component
                  .getComponents()
                  .findById("login-register-dialog")
                  .first()
                  .setData({
                    "default.open": true,
                  })
              }
            >
              Order Now
            </Button>
          ) : (
            <>
              <IconsButton
                aria-owns={anchorEl ? "simple-menu" : undefined}
                aria-haspopup="true"
                onClick={(e) => handleClick(e, "account")}
                disableRipple
                sx={{ mr: { xs: "0px", md: "5px" } }}
              >
                <CouponBadge badgeContent={coupons.length} color="primary">
                  <Avatar
                    sx={{
                      width: { xs: "30px", md: "34px" },
                      height: { xs: "30px", md: "34px" },
                      fontSize: { xs: "14px", md: "16px" },
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "#000",
                      color: "#fff",
                    }}
                  >
                    <Box>{username}</Box>
                  </Avatar>
                </CouponBadge>
              </IconsButton>
              <IconsButton
                disableRipple
                onClick={() => {
                  component.toggleCartDrawer();
                }}
                sx={{ mr: { xs: "0px", md: "5px" } }}
                aria-label="Cart"
              >
                <StyledBadge
                  badgeContent={devHelper.getObjectValue(
                    order,
                    "amounts.items",
                    0
                  )}
                  showZero
                  sx={{
                    fontSize: { xs: "20px", md: "22px" },
                    "& .MuiBadge-badge": {
                      visibility:
                        devHelper.getObjectValue(order, "amounts.items", 0) ===
                        0
                          ? "hidden"
                          : "visible",
                    },
                  }}
                >
                  <i className="fa-light fa-bag-shopping"></i>
                </StyledBadge>
              </IconsButton>
            </>
          )}
        </Box>
      </Container>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.1))",
            mt: "10px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 58,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        <MyMenuItem component={RouterLink} to="/profile" onClick={handleClose}>
          {component.ucfirst("profile-btn")}
        </MyMenuItem>
        <MyMenuItem
          component={RouterLink}
          to="/addresses"
          onClick={handleClose}
        >
          {component.ucfirst("addresses-btn")}
        </MyMenuItem>
        <MyMenuItem component={RouterLink} to="/orders" onClick={handleClose}>
          {component.ucfirst("orders-btn")}
        </MyMenuItem>
        <MyMenuItem component={RouterLink} to="/coupons" onClick={handleClose}>
          <CouponBadge2 badgeContent={coupons.length} color="primary">
            {component.ucfirst("coupons-btn")}
          </CouponBadge2>
        </MyMenuItem>
        <MyMenuItem onClick={() => component.getPage().logout()}>
          {component.ucfirst("logout-btn")}
        </MyMenuItem>
      </Menu>
    </Toolbar>
  );
}
