import React from "react";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Container,
  IconButton,
  OutlinedInput,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Typography,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const SubmitBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "12px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

export default function Profile(props) {
  const { component } = props;

  const [values, setValues] = React.useState({
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Container
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <Box
        sx={{
          borderTop: "2px solid #f05d32",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: -1,
          my: 6,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            zIndex: 1,
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50px",
            color: "#000",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            px: 4,
            fontSize: "35px",
            fontWeight: 400,
          }}
        >
          {component.ucfirst("profile-btn")}
        </Typography>
      </Box>
      <form
        style={{ width: "100%", marginTop: "8px" }}
        onSubmit={(e) => component.updateProfile(e)}
      >
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="name"
          label={component.ucfirst("fullname-textfield")}
          name="name"
          helperText={component.getData("error.fields.name")}
          error={component.dataExists("error.fields.name")}
          value={component.getData("default.fields.name", "")}
          onChange={(e) => {
            component.setData({ "default.fields.name": e.target.value });
            component.deleteData("error.fields.name");
          }}
        />
        <MyTextField
          disabled
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={component.ucfirst("email-textfield")}
          name="email"
          value={component.getData("default.fields.username", "")}
        />

        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.password")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.password", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.password": e.target.value,
              });
              component.deleteData("error.fields.password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disableRipple
                  sx={{
                    "&:hover, &:focus": { backgroundColor: "transparent" },
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={component.ucfirst("password-textfield")}
          />
          {component.dataExists("error.fields.password") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.password")}
            </FormHelperText>
          )}
        </PasswordField>

        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.verify")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("confirm-password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.verify", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.verify": e.target.value,
              });
              component.deleteData("error.fields.verify");
            }}
            label={component.ucfirst("confirm-password-textfield")}
          />
          {component.dataExists("error.fields.verify") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.verify")}
            </FormHelperText>
          )}
        </PasswordField>

        <SubmitBtn type="submit" fullWidth variant="contained">
          {component.ucfirst("submit-btn")}
        </SubmitBtn>
      </form>
    </Container>
  );
}
