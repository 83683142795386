import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Grid,
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";
import ProductCheckoutView from "../../../../Components/Widgets/ProductCheckoutView";

const SubTitle = styled(Typography)(({ theme }) => ({
  margin: "20px 0px",
  fontWeight: 400,
  fontSize: 20,
  border: "1px solid #000",
  fontFamily: "founders-grotesk,Helvetica,sans-serif",
  textAlign: "center",
  padding: 10,
}));

const InnerTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: 18,
  fontFamily: "founders-grotesk,Helvetica,sans-serif",
  textAlign: "center",
}));

const MyTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  width: "100%",
  overflow: "hidden",
  textOverflow: "ellipsis",
}));

const MyTableCell = styled(TableCell)(({ theme }) => ({
  padding: "8px 0px",
  borderBottom: "none",
  fontSize: 16,
  "@media (max-width: 580px )": {
    padding: "8px 10px 8px 0px",
  },
}));

export default function Order(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const order = component.getData("default.order", null);

  const months = [
    component.ucfirst("jan"),
    component.ucfirst("feb"),
    component.ucfirst("mar"),
    component.ucfirst("apr"),
    component.ucfirst("may"),
    component.ucfirst("jun"),
    component.ucfirst("jul"),
    component.ucfirst("aug"),
    component.ucfirst("sep"),
    component.ucfirst("oct"),
    component.ucfirst("nov"),
    component.ucfirst("dec"),
  ];
  const days = [
    component.ucfirst("sunday"),
    component.ucfirst("monday"),
    component.ucfirst("tuesday"),
    component.ucfirst("wednesday"),
    component.ucfirst("thursday"),
    component.ucfirst("friday"),
    component.ucfirst("saturday"),
  ];

  const delivery_type = devHelper.getObjectValue(order, "delivery_type");

  var date = null,
    time = null;
  if (order) {
    date = devHelper
      .getObjectValue(order, "_updated_at")
      .split(" ")
      .splice(0, 1)
      .join("");

    time = devHelper
      .getObjectValue(order, "_updated_at")
      .split(" ")
      .splice(-1)
      .join("");
  }

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        my: 2,
        px: { xs: 2, md: 3 },
      }}
      maxWidth="xl"
      disableGutters
    >
      {!order ? (
        <CircularProgress
          style={{
            margin: "80px 0",
            color: "#000",
          }}
        />
      ) : (
        <Grid
          container
          sx={{
            flexGrow: 1,
            justifyContent: "center",
            alignItems: "flex-start",
          }}
          direction="row"
          spacing={4}
        >
          <Grid item md={7} xs={12}>
            <Typography
              component="h1"
              sx={{
                mt: 1,
                mb: 3,
                fontWeight: 500,
                fontSize: { xs: 20, md: 25 },
                flexGrow: 1,
              }}
            >
              {component.ucfirst("order")}{" "}
              {devHelper.getObjectValue(order, "_id")}
            </Typography>
            <MyTypography variant="subtitle1">
              {`${days[new Date(date).getUTCDay()]}, ${
                months[new Date(date).getMonth()]
              } ${new Date(date).getDate()} ${new Date(
                date
              ).getFullYear()} ${time}`}
            </MyTypography>
            <MyTypography variant="subtitle1">
              <b> {component.ucfirst("items")}:</b>{" "}
              {devHelper.getObjectValue(order, "amounts.items")}
            </MyTypography>
            <MyTypography variant="subtitle1">
              <b> {component.ucfirst("status")}:</b>{" "}
              {component.ucfirst(devHelper.getObjectValue(order, "_status"))}
            </MyTypography>
            <SubTitle variant="h3" gutterBottom>
              {component.ucfirst("shipping-information-title")}
            </SubTitle>
            <Grid container justifycontent="center" spacing={4}>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("shipping-address")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {/* {component.ucfirst(
                    devHelper.getObjectValue(
                      order,
                      "shipping_address.first_name"
                    )
                  )} 
                   <br /> */}
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.address")
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.phone")
                  )}
                  <br />
                  <b>{component.ucfirst("bell-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.bell")
                  )}
                  <br />
                  <b>{component.ucfirst("floor-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.floor")
                  )}
                  <br />
                  <b>{component.ucfirst("notes-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.notes")
                  )}
                  <br />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("shipping-method")}
                </InnerTitle>
                <Box sx={{ textAlign: "center" }}>
                  {component.ucfirst(delivery_type)}
                </Box>
                {/* {order.services.map((service) => {
                  if (service.selected && service.scope === "shipping") {
                    return (
                      <div
                        key={devHelper.getObjectValue(service, "slug")}
                        style={{ textAlign: "center" }}
                      >
                        {component.ucfirst(
                          devHelper.getObjectValue(service, "name")
                        )}
                      </div>
                    );
                  } else {
                    return (
                      <React.Fragment
                        key={devHelper.getObjectValue(service, "slug")}
                      ></React.Fragment>
                    );
                  }
                })} */}
              </Grid>
            </Grid>
            <SubTitle variant="h3" gutterBottom>
              {component.ucfirst("billing-information-title")}
            </SubTitle>
            <Grid container justifycontent="center" spacing={4}>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("billing-address")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {/* {component.ucfirst(
                    devHelper.getObjectValue(
                      order,
                      "shipping_address.first_name"
                    )
                  )} 
                   <br /> */}
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.address")
                  )}
                  <br />
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.phone")
                  )}
                  <br />
                  <b>{component.ucfirst("bell-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.bell")
                  )}
                  <br />
                  <b>{component.ucfirst("floor-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.floor")
                  )}
                  <br />
                  <b>{component.ucfirst("notes-textfield")}: </b>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "shipping_address.notes")
                  )}
                  <br />
                </div>
              </Grid>
              <Grid item sm={6} xs={12}>
                <InnerTitle variant="h3" gutterBottom>
                  {component.ucfirst("payment-method")}
                </InnerTitle>
                <div style={{ textAlign: "center" }}>
                  {component.ucfirst(
                    devHelper.getObjectValue(order, "paymentType.name")
                  )}
                </div>
              </Grid>
            </Grid>
          </Grid>
          {/* order summary */}
          <Grid item md={5} xs={12}>
            <Paper
              square
              elevation={3}
              sx={{
                display: "flex",
                justifyContent: "center",
                padding: "10px 25px",
              }}
            >
              <Box style={{ width: "100%" }}>
                <Typography
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "25px", md: "30px" },
                    lineHeight: 1,
                    mb: 2,
                  }}
                >
                  {component.ucfirst("checkout-title-4")}
                </Typography>
                {order && order.items.length !== 0 && (
                  <Box>
                    <List dense sx={{ width: "100%", justifyContent: "top" }}>
                      {order.items.map((product, index) => (
                        <ProductCheckoutView
                          key={index}
                          {...{
                            ...props,
                            ...{
                              product,
                            },
                          }}
                        />
                      ))}
                    </List>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <MyTableCell
                            rowSpan={5}
                            sx={{ display: { xs: "none", md: "table-cell" } }}
                          />
                          <MyTableCell
                            rowSpan={5}
                            sx={{ display: { xs: "none", md: "table-cell" } }}
                          />
                          <MyTableCell>
                            {component.ucfirst("subtotal")}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(order, "totals.items")
                              )}
                          </MyTableCell>
                        </TableRow>
                        {order.services
                          .filter((service) => service.selected)
                          .map((service) => (
                            <TableRow
                              key={devHelper.getObjectValue(service, "slug")}
                            >
                              <MyTableCell>
                                {component.ucfirst(
                                  devHelper.getObjectValue(service, "slug")
                                )}
                              </MyTableCell>
                              <MyTableCell align="right">
                                {component
                                  .getApp()
                                  .priceFormater(
                                    devHelper.getObjectValue(service, "price")
                                  )}
                              </MyTableCell>
                            </TableRow>
                          ))}
                        {devHelper.getObjectValue(order, "totals.discount") ? (
                          <TableRow>
                            <MyTableCell>
                              {component.ucfirst("discount")}
                            </MyTableCell>
                            <MyTableCell align="right">
                              -
                              {component
                                .getApp()
                                .priceFormater(
                                  devHelper.getObjectValue(
                                    order,
                                    "totals.discount"
                                  )
                                )}
                            </MyTableCell>
                          </TableRow>
                        ) : (
                          <></>
                        )}
                        <TableRow>
                          <MyTableCell sx={{ fontWeight: 500 }}>
                            {component.ucfirst("total")}
                          </MyTableCell>
                          <MyTableCell sx={{ fontWeight: 500 }} align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(order, "totals.grand")
                              )}
                          </MyTableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Box>
                )}
              </Box>
            </Paper>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
