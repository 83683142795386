import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/deal_dialog";
import Services from "../../../Services/Services";

export default class DealDialog extends Component {
  id = "deal-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });
  }

  setProduct(product) {
    if (!product.amount) {
      product.amount = 1;
    }

    if (!product.item) {
      product.item = {
        ...{ ...product._values },
        ...{ id: product._id },
      };
      product.price = product._values.price;
      product.special_price = product._values.special_price
        ? product._values.special_price
        : 0;
    }

    this.setData({
      "default.product": product,
      "default.selectedProducts": this.prepareSelectedProducts(product),
      "default.open": true,
    });
    this.getApp().sendToGA4(product, "view_item");

    window.location.href = "#product";

    window.onhashchange = () => {
      if (!window.location.hash) {
        this.setData({ "default.open": false });
        window.onhashchange = null;
      }
    };
  }

  prepareSelectedProducts(product) {
    const types = this.getTypes(product);
    const { items } = product;

    return items ? items : new Array(types.length);
  }

  getTypes(product) {
    product = product ? product : this.getData("default.product", {});

    // const { _values = {} } = product.ite;
    const { types = [] } = product.item || {};
    let productTypes = [];

    if (types.length) {
      const value2 = types[0].value2;

      if (value2) {
        productTypes = value2.split(",");
      }
    }

    return productTypes;
  }

  getProductsByType(productType) {
    const { product = {} } = this.getData("default", {});
    // const { _values = {} } = product.item;
    let { products = [] } = product.item;

    products = products.filter((product) => {
      const { item } = product;
      const { _values } = item;
      const { types } = _values;

      return types.find((type) => {
        return type.item._values.slug.indexOf(productType) >= 0;
      })
        ? true
        : false;
    });

    return products;
  }

  findProduct(slug) {
    const { product = {} } = this.getData("default", {});
    // const { _values = {} } = product;
    let { products = [] } = product.item;

    return products.find((product) => product.item._values.slug === slug);
  }

  addProduct(slug, index) {
    const products = this.getData("default.selectedProducts", {});

    // if (!products[index]) {
    const product = this.findProduct(slug);

    if (product) {
      const { slug, name } = product.item._values;
      products[index] = { slug, name };
    }
    // }

    this.setData({ "default.selectedProducts": products });
  }

  getProduct(index) {
    const products = this.getData("default.selectedProducts", {});
    const product = products[index];
    return product ? product.slug : "";
  }

  closeDialog() {
    // this.setData({
    //   "default.open": false,
    // });
    window.location.href = "#";
  }

  handleClick(product, qnt) {
    const isAuth = this.isAuthenticated();

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");

          var comp_id = null;

          if (isAuth) {
            if (order) {
              var { shipping_address, delivery_type, spot } = order;
              if (!delivery_type) {
                comp_id = "delivery-type-dialog";
              } else {
                if (delivery_type === "delivery" && !shipping_address) {
                  comp_id = "select-address-dialog";
                } else if (delivery_type === "takeaway" && !spot) {
                  comp_id = "select-spot-dialog";
                } else {
                  this.updateCart(product, qnt);
                }
              }
            } else {
              comp_id = "delivery-type-dialog";
            }
          } else {
            comp_id = "login-register-dialog";
          }

          this.setData({ "default.open": false });

          if (comp_id) {
            this.getComponents()
              .findById(comp_id)
              .forEach((comp) => {
                comp.setData({
                  "default.open": true,
                });
                if (
                  typeof delivery_type !== "undefined" &&
                  delivery_type === "delivery"
                ) {
                  comp.retrieveAddresses();
                  comp.initGoogleAutocomplete("shipping_address");
                }
              });
          }
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  async updateCart(product) {
    const [orderService] = await Services.get("order");
    const selectedProducts = this.getData("default.selectedProducts");

    let { instance, amount } = product;
    const { id } = product.item;

    if (!instance) {
      instance = new Date().getTime();
    }

    if (amount) {
      const foundProduct = this.findInstance(
        orderService.getOrder(),
        product,
        selectedProducts
      );

      if (foundProduct) {
        amount += foundProduct.amount;
        await this.updateCart({ ...foundProduct, ...{ amount: 0 } });
      }
    }

    if (this.hasProductSelected()) {
      await orderService.updateItem({
        itemId: id,
        itemType: "product",
        itemInstance: instance,
        items: selectedProducts,
        values: {
          ingredients: [],
        },
        amount,
      });

      if (amount !== 0) {
        this.getApp().sendToGA4({ ...product }, "add_to_cart", amount);
      }

      this.getComponents()
        .findByGroup("order")
        .forEach((order) => {
          order.setData({ "default.order": orderService.getOrder() });
        });

      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "success-message": this.trans("product-updated-message"),
        });
    } else {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "error-message": this.trans("product-missing-message"),
        });
    }
  }

  hasProductSelected() {
    const selectedProducts = this.getData("default.selectedProducts", []);

    return (
      selectedProducts.filter((product) => product).length ===
      selectedProducts.length
    );
  }

  findInstance(order, newProduct, selectedProducts) {
    const newProductId = newProduct.item.id;
    const newProductInstance = newProduct.instance;

    const { items = [] } = order;

    let orderItem = items.find((orderItem) => {
      return (
        orderItem.item.id === newProductId &&
        orderItem.instance !== newProductInstance &&
        orderItem.items &&
        orderItem.items.length === selectedProducts.length &&
        orderItem.items.filter(
          (orderItemProduct) =>
            !selectedProducts.find(
              (selectedProduct) =>
                selectedProduct.slug === orderItemProduct.slug
            )
        ).length === 0
      );
    });

    return orderItem;
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.setData({ "default.open": false });
          this.getComponents()
            .findById("delivery-type-dialog")
            .first()
            .setData({
              "default.open": true,
            });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
