import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Skeleton,
  ListItemText,
  CardMedia,
  Button,
  Typography,
  useScrollTrigger,
  Collapse,
  ButtonGroup,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        display: "flex",
        alignItems: "center",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
            padding: "4px",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            borderRadius: "100%",
            zIndex: 1302,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ProductDialog(props) {
  const { component } = props;
  const ref = React.useRef();

  function ShowOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      target: ref.current ? ref.current : undefined,
      disableHysteresis: true,
      threshold: useMediaQuery(useTheme().breakpoints.down("sm")) ? 300 : 350,
    });

    return (
      <Collapse
        appear={false}
        direction="down"
        in={trigger}
        timeout={{ enter: 0, exit: 0 }}
      >
        {children}
      </Collapse>
    );
  }

  const devHelper = component.getHelpers("dev");
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const {
    open = false,
    product = null,
    ingredient_types = [],
  } = component.getData("default", {});

  const setQnt = (qnt) => {
    product.amount = product.amount + qnt;
    component.setData({ "default.product": product });
  };

  const Title = () => (
    <ListItemText
      sx={{
        px: 2,
        py: 1,
        m: 0,
        backgroundColor: "#fff",
        width: "100%",
        boxShadow:
          devHelper.getObjectValue(product, "item.ingredients", []).length !== 0
            ? "4px 4px 8px 0 rgb(0 0 0 / 8%)"
            : "none",
      }}
      primary={
        <span
          style={{
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          <Translate
            entity="item"
            eslug={devHelper.getObjectValue(product, "item.slug")}
            scope="default"
            slug="name"
            auto
          />
        </span>
      }
      secondary={
        <>
          <Translate
            entity="item"
            eslug={devHelper.getObjectValue(product, "item.slug")}
            scope="default"
            slug="description"
            auto
          />
          <Box sx={{ mt: 1 }}>
            {devHelper.getObjectValue(product, "price", 0) > 0 && (
              <Box
                component="span"
                sx={{
                  fontSize: 16,
                  fontWeight: 500,
                  color: "#000",
                }}
              >
                {component
                  .getApp()
                  .priceFormater(
                    devHelper.getObjectValue(product, "special_price", 0) > 0
                      ? devHelper.getObjectValue(product, "special_price", 0)
                      : devHelper.getObjectValue(product, "price", 0)
                  )}
              </Box>
            )}
            {devHelper.getObjectValue(product, "special_price", 0) > 0 && (
              <Box
                component="span"
                sx={{
                  ml: 1,
                  textDecoration: "line-through",
                  color: "#f05d32",
                }}
              >
                <Box
                  component="span"
                  sx={{
                    fontSize: 14,
                    color: "#777",
                    fontWeight: 400,
                  }}
                >
                  {component
                    .getApp()
                    .priceFormater(devHelper.getObjectValue(product, "price"))}
                </Box>
              </Box>
            )}
          </Box>
        </>
      }
      secondaryTypographyProps={{ sx: { mt: 1 }, component: "div" }}
    />
  );

  return (
    <Dialog
      onClose={() => component.closeDialog()}
      fullScreen={fullScreen}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: "0px", sm: "25px" },
          minWidth: { xs: "unset", md: "700px" },
          bgcolor: "transparent",
        },
        "& .MuiDialogContent-root::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <ShowOnScroll {...props}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1301,
              width: "100%",
            }}
          >
            {Title()}
          </Box>
        </ShowOnScroll>
      </Box>
      <MyDialogTitle onClose={() => component.closeDialog()}></MyDialogTitle>
      <DialogContent
        ref={ref}
        sx={{
          backgroundColor:
            devHelper.getObjectValue(product, "item.ingredients", []).length !==
            0
              ? "#f7f7f7"
              : "#fff",
          p: 0,
          maxHeight: { xs: "unset", sm: "640px" },
        }}
      >
        <Box
          sx={{
            height: { xs: "300px", sm: "350px" },
          }}
        >
          {!component.getData("default.imageReady", false) && (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          )}
          <CardMedia
            component="img"
            alt={devHelper.getObjectValue(product, "item.name") + " image"}
            sx={{
              maxWidth: "100%",
              height: "100%",
            }}
            src={`${filesUrl}/products/images/${devHelper.getObjectValue(
              product,
              "item.slug"
            )}.png?v=${img_version}`}
            onError={(e) => {
              e.target.src = `${filesUrl}/assets/images/not-found.png`;
            }}
            onLoad={() => {
              component.setData({ "default.imageReady": true });
            }}
          />
        </Box>
        {Title()}
        {devHelper.getObjectValue(product, "item.ingredients", []).length !==
          0 && (
          <Box sx={{ p: 2, pt: 1 }}>
            {ingredient_types.map((ingredient_type) => {
              var products_ingredients = product.item.ingredients.filter(
                (ingredient) =>
                  ingredient.item._values.ingredient_type.find(
                    (current) =>
                      current.item._values.slug === ingredient_type._values.slug
                  )
              );
              if (products_ingredients.length !== 0) {
                products_ingredients.sort(function (a, b) {
                  return a.position - b.position;
                });

                return (
                  <Box key={devHelper.getObjectValue(ingredient_type, "_id")}>
                    <Typography
                      sx={{
                        py: 1,
                        fontSize: "16px",
                        fontWeight: 600,
                      }}
                    >
                      <Translate
                        entity="item"
                        eslug={devHelper.getObjectValue(
                          ingredient_type,
                          "_values.slug"
                        )}
                        scope="default"
                        slug="name"
                        auto
                      />
                    </Typography>
                    {!devHelper.getObjectValue(
                      ingredient_type,
                      "_values.multiple"
                    ) ? (
                      <RadioGroup
                        key={devHelper.getObjectValue(ingredient_type, "_id")}
                        value={
                          products_ingredients.find(
                            (ingredient) => ingredient.selected
                          ).item._values.slug
                        }
                      >
                        <Grid
                          container
                          rowSpacing={{ xs: 1 }}
                          columnSpacing={{ xs: 1 }}
                        >
                          {products_ingredients.map((ingredient) => {
                            return (
                              <Grid
                                key={devHelper.getObjectValue(
                                  ingredient,
                                  "item._values.slug"
                                )}
                                item
                                md={6}
                                xs={12}
                              >
                                <FormControlLabel
                                  value={devHelper.getObjectValue(
                                    ingredient,
                                    "item._values.slug"
                                  )}
                                  sx={{
                                    m: 0,
                                    width: "100%",
                                    boxShadow: "0 2px 4px 0 rgb(0 0 0 / 4%)",
                                    bgcolor: "#fff",
                                    borderRadius: "12px",
                                    ".MuiTypography-root": {
                                      width: "100%",
                                      fontSize: { xs: 12, sm: 14 },
                                      pr: "12px",
                                    },
                                  }}
                                  control={
                                    <Radio
                                      sx={{
                                        color: "#dadada",
                                        "&.Mui-checked": {
                                          color: "#f05d32",
                                          "&:hover": {
                                            background: "transparent",
                                          },
                                        },
                                        "&:hover": {
                                          background: "transparent",
                                        },
                                      }}
                                    />
                                  }
                                  onClick={() =>
                                    component.updateIngredients(
                                      product,
                                      devHelper.getObjectValue(
                                        ingredient,
                                        "item._values.slug"
                                      ),
                                      ingredient_type
                                    )
                                  }
                                  label={
                                    <>
                                      <span>
                                        <Translate
                                          entity="item"
                                          eslug={devHelper.getObjectValue(
                                            ingredient,
                                            "item._values.slug"
                                          )}
                                          scope="default"
                                          slug="name"
                                          auto
                                        />
                                      </span>
                                      <span style={{ float: "right" }}>
                                        {component
                                          .getApp()
                                          .priceFormater(
                                            devHelper.getObjectValue(
                                              ingredient,
                                              "price"
                                            )
                                          )}
                                      </span>
                                    </>
                                  }
                                />
                              </Grid>
                            );
                          })}
                        </Grid>
                      </RadioGroup>
                    ) : (
                      <Grid
                        container
                        rowSpacing={{ xs: 1 }}
                        columnSpacing={{ xs: 1 }}
                      >
                        {products_ingredients.map((ingredient) => {
                          return (
                            <Grid
                              key={devHelper.getObjectValue(
                                ingredient,
                                "item._values.slug"
                              )}
                              item
                              md={6}
                              xs={12}
                            >
                              <FormControlLabel
                                value={devHelper.getObjectValue(
                                  ingredient,
                                  "item._values.slug"
                                )}
                                sx={{
                                  m: 0,
                                  width: "100%",
                                  boxShadow: "0 2px 4px 0 rgb(0 0 0 / 4%)",
                                  bgcolor: "#fff",
                                  borderRadius: "12px",
                                  ".MuiTypography-root": {
                                    width: "100%",
                                    fontSize: { xs: 12, sm: 14 },
                                    pr: "12px",
                                  },
                                }}
                                control={
                                  <Checkbox
                                    checked={devHelper.getObjectValue(
                                      ingredient,
                                      "selected"
                                    )}
                                    onChange={() =>
                                      component.updateIngredients(
                                        product,
                                        devHelper.getObjectValue(
                                          ingredient,
                                          "item._values.slug"
                                        ),
                                        ingredient_type
                                      )
                                    }
                                    sx={{
                                      marginTop: "-2px",
                                      color: "#dadada",
                                      "&.Mui-checked": {
                                        color: "#f05d32",
                                      },
                                      "&:hover": { bgcolor: "transparent" },
                                    }}
                                  />
                                }
                                label={
                                  <>
                                    <span>
                                      <Translate
                                        entity="item"
                                        eslug={devHelper.getObjectValue(
                                          ingredient,
                                          "item._values.slug"
                                        )}
                                        scope="default"
                                        slug="name"
                                        auto
                                      />
                                    </span>
                                    <span style={{ float: "right" }}>
                                      {component
                                        .getApp()
                                        .priceFormater(
                                          devHelper.getObjectValue(
                                            ingredient,
                                            "price"
                                          )
                                        )}
                                    </span>
                                  </>
                                }
                              />
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </Box>
                );
              } else {
                return (
                  <Box
                    key={devHelper.getObjectValue(ingredient_type, "_id")}
                  ></Box>
                );
              }
            })}
          </Box>
        )}
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            devHelper.getObjectValue(product, "_values.ingredients", [])
              .length !== 0
              ? { xs: "none", sm: "0 -4px 12px 0 rgb(0 0 0 / 5%)" }
              : "none",
          pt: { xs: 0, sm: 1 },
          pb: 1,
          px: "4px",
          bgcolor: { xs: "#f7f7f7", sm: "#fff" },
        }}
      >
        <Grid
          container
          rowSpacing={{ xs: 1 }}
          columnSpacing={{ xs: "4px", sm: 1 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} sm={4}>
            <ButtonGroup
              fullWidth
              sx={{
                display: "flex",
                bgcolor: "#dddad4",
                mx: "auto",
                borderRadius: "25px",
                "& .MuiButton-root": {
                  fontSize: { xs: 13, sm: 16 },
                  border: "none",
                  color: "#000",
                  height: "44px",
                  px: { xs: "5px", sm: "15px" },
                  "&:hover": {
                    border: "none",
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                  "&:disabled": {
                    fontSize: { xs: 13, sm: 16 },
                    border: "none",
                    color: "#7d7d7d",
                  },
                },
              }}
            >
              <Button
                onClick={() => setQnt(-1)}
                disabled={devHelper.getObjectValue(product, "amount") === 1}
                sx={{
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                }}
              >
                <i className="fa-solid fa-minus" />
              </Button>
              <Button
                disabled
                sx={{
                  "&:disabled": {
                    height: "36px",
                    mt: 0.5,
                    fontSize: "16px",
                    color: "#fff !important",
                    bgcolor: "#000",
                    borderRadius: "25px !important",
                  },
                }}
              >
                {devHelper.getObjectValue(product, "amount")}
              </Button>
              <Button
                onClick={() => setQnt(1)}
                sx={{
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                }}
              >
                <i className="fa-solid fa-plus" />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              sx={{
                fontSize: { xs: 13, sm: 16 },
                border: "1px solid #f05d32",
                backgroundColor: "#f05d32",
                color: "#fff",
                borderRadius: "25px",
                height: "44px",
                "&:hover": {
                  backgroundColor: "#f05d32",
                  border: "1px solid #f05d32",
                },
              }}
              onClick={() => {
                component.handleClick(product);
              }}
            >
              {component.trans("add-to-cart-btn")}{" "}
              {component
                .getApp()
                .priceFormater(
                  devHelper.getObjectValue(product, "special_price", 0) > 0
                    ? devHelper.getObjectValue(product, "special_price") *
                        devHelper.getObjectValue(product, "amount")
                    : devHelper.getObjectValue(product, "price") *
                        devHelper.getObjectValue(product, "amount")
                )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
