import React from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  SwipeableDrawer,
  List,
  Button,
  Typography,
  Box,
  IconButton,
} from "@mui/material";
import ProductCartView from "../../../../Components/Widgets/ProductCartView";
import ProductDialog from "../../../../Components/Widgets/Dialogs/ProductDialog";

export default function CartDrawer(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null, ingredient_types = [] } = component.getData(
    "default",
    {}
  );

  return (
    <>
      <SwipeableDrawer
        anchor="right"
        open={component.getData("default.opened", false)}
        onClose={() => component.toggle()}
        onOpen={() => {}}
        transitionDuration={{ enter: 500, exit: 500 }}
        swipeAreaWidth={0}
        sx={{
          ".MuiDrawer-paper": {
            height: "100%",
            padding: "30px 20px",
            width: { xs: "100%", sm: "500px" },
            zIndex: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          },
        }}
      >
        <IconButton
          onClick={() => component.toggle()}
          sx={{
            position: "absolute",

            left: 5,
            top: 10,
            fontSize: 25,
            color: "#000",
          }}
        >
          <i className="fa-regular fa-xmark" />
        </IconButton>
        <Box
          sx={{
            borderTop: "2px solid #f05d32",
            width: "100%",
            textAlign: "center",
            position: "relative",
            zIndex: -1,
            my: 4,
          }}
        >
          <Typography
            variant="h1"
            sx={{
              zIndex: 1,
              position: "absolute",
              top: "0px",
              left: "50%",
              transform: "translate(-50%, -50%)",
              borderRadius: "50px",
              color: "#000",
              backgroundColor: "#fff",
              border: "1px solid #fff",
              px: 4,
              fontSize: "35px",
              fontWeight: 400,
            }}
          >
            {component.ucfirst("cart")}
          </Typography>
        </Box>
        {order && order.items && order.items.length !== 0 ? (
          <>
            <List dense sx={{ width: "100%", justifyContent: "top" }}>
              {order.items.map((product, index) => (
                <ProductCartView
                  key={index}
                  {...{
                    ...props,
                    ...{
                      product,
                      ingredient_types,
                    },
                  }}
                />
              ))}
            </List>
            <Box
              sx={{
                my: 2,
                fontWeight: 500,
              }}
            >
              {component.ucfirst("total")}
              <Box
                component="span"
                sx={{ ml: 1, fontWeight: 600, fontSize: "18px" }}
              >
                {component
                  .getApp()
                  .priceFormater(
                    devHelper.getObjectValue(order, "totals.items")
                  )}
              </Box>
            </Box>
            <Button
              component={RouterLink}
              to="/checkout"
              fullWidth
              variant="contained"
              sx={{
                padding: "8px 14px",
                border: "1px solid #f05d32",
                bgcolor: "#f05d32",
                borderRadius: "12px",
                color: "#fff",
                "&:hover": {
                  bgcolor: "#f05d32",
                },
              }}
              onClick={() => component.toggle()}
            >
              {component.trans("checkout")}
            </Button>
          </>
        ) : (
          <div
            style={{
              textAlign: "center",
              marginTop: 50,
            }}
          >
            <Typography>{component.trans("empty-cart-message")}</Typography>
          </div>
        )}
      </SwipeableDrawer>
      <ProductDialog
        {...{
          ...props,
          ...{},
        }}
      />
    </>
  );
}
