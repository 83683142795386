import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/main_appbar";
import Services from "../../Services/Services";

export default class MainAppbar extends Component {
  id = "main-appbar";
  group = "wishlist,order";

  template = templateDefault;

  onAuth() {
    this.refresh();
    this.retrieveCoupons();
  }

  onLoad(data) {
    super.onLoad(data);
    this.getOrder();
    this.retrieveCoupons();
  }

  getOrder() {
    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");
          this.setData({ "default.order": order });
        })
        .catch((orderService) => {});
    });
  }

  updateDeliveryType(type) {
    type = type === "delivery" ? "takeaway" : "delivery";
    Services.get("order").then(async ([orderService]) => {
      orderService
        .updateDeliveryType({
          type,
        })
        .then((orderService) => {
          const order = orderService.getOrder();
          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });
          this.setData({
            "default.open": false,
          });
          this.showDialog(type);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  showDialog(type) {
    var comp_id =
      type === "delivery" ? "select-address-dialog" : "select-spot-dialog";
    this.getComponents()
      .findById(comp_id)
      .forEach((comp) => {
        comp.setData({
          "default.open": true,
        });
        if (type === "delivery") {
          setTimeout(() => {
            comp.retrieveAddresses();
            comp.initGoogleAutocomplete("shipping_address");
          }, 500);
        }
      });
  }

  toggleMenuDrawer() {
    this.getComponents()
      .findById("menu-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  toggleCartDrawer() {
    this.getComponents()
      .findById("cart-drawer")
      .forEach((drawer) => {
        drawer.toggle();
      });
  }

  retrieveCoupons() {
    if (this.isAuthenticated()) {
      Services.get("coupon").then(([couponService]) => {
        couponService
          .getCoupons()
          .then((couponService) => {
            const coupons = couponService.getData("coupons", []);
            this.setData({
              "default.coupons": coupons,
            });
          })
          .catch((err) => {
            console.log("error", err);
          });
      });
    }
  }
}
