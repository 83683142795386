import React from "react";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import {
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  OutlinedInput,
  InputLabel,
  FormHelperText,
  FormControl,
  InputAdornment,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "12px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

MyDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function ResetPasswordDialog(props) {
  const { component } = props;

  const [values, setValues] = React.useState({
    password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Dialog
      onClose={() => component.getPage().redirect("/")}
      open={component.getPage().getQuery("resetPassword", false) ? true : false}
      // open={true}
      sx={{
        "& .MuiDialogContent-root": { padding: "16px" },
        "& .MuiDialogActions-root": {
          padding: "8px",
        },
        "& .MuiPaper-rounded": {
          borderRadius: "25px",
        },
      }}
      maxWidth="xs"
    >
      <MyDialogTitle
        id="customized-dialog-title"
        onClose={() => component.getPage().redirect("/")}
      >
        {component.trans("reset-password-title")}
      </MyDialogTitle>

      <DialogContent dividers>
        <Typography variant="body2">
          {component.trans("reset-password-text-2")}
        </Typography>
        <form onSubmit={(e) => component.resetPassword(e)}>
          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.password")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.password", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.password": e.target.value,
                });
                component.deleteData("error.fields.password");
              }}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    disableRipple
                    sx={{
                      "&:hover, &:focus": { backgroundColor: "transparent" },
                    }}
                  >
                    {values.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              label={component.ucfirst("password-textfield")}
            />
            {component.dataExists("error.fields.password") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.password")}
              </FormHelperText>
            )}
          </PasswordField>

          <PasswordField
            variant="outlined"
            error={component.dataExists("error.fields.verify")}
          >
            <InputLabel htmlFor="outlined-adornment-password">
              {component.ucfirst("confirm-password-textfield")}
            </InputLabel>
            <OutlinedInput
              type={values.showPassword ? "text" : "password"}
              value={component.getData("default.fields.verify", "")}
              onChange={(e) => {
                component.setData({
                  "default.fields.verify": e.target.value,
                });
                component.deleteData("error.fields.verify");
              }}
              label={component.ucfirst("confirm-password-textfield")}
            />
            {component.dataExists("error.fields.verify") && (
              <FormHelperText error style={{ margin: "3px 14px 0" }}>
                {component.getData("error.fields.verify")}
              </FormHelperText>
            )}
          </PasswordField>
          <LoginRegisterBtn type="submit" fullWidth variant="contained">
            {component.trans("submit-btn")}
          </LoginRegisterBtn>
        </form>
      </DialogContent>
    </Dialog>
  );
}
