import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/checkout_navbar";

export default class CheckoutNavbar extends Component {
  id = "checkou-navbar";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }
}
