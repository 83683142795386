import React from "react";
import {
  Box,
  ListItem,
  Button,
  Typography,
  ButtonGroup,
  Skeleton,
  ListItemText,
  Avatar,
  IconButton,
  CardMedia,
} from "@mui/material";
import Translate from "../../../../../modules/Core/Components/Translate";

export default function ProductCartView(props) {
  const { component } = props;
  const product = component.props.product;

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  if (product) {
    var default_ingredients = product.item.ingredients;
    var selected_ingredients = product.values.ingredients;
    product.item.price = product.price;
    default_ingredients.forEach((default_ing) => {
      if (selected_ingredients.includes(default_ing.item._values.slug)) {
        default_ing.selected = true;
      } else {
        default_ing.selected = false;
      }
    });
  }
  return (
    <>
      {product && (
        <ListItem
          disablePadding
          sx={{
            pt: 2,
            position: "relative",
            borderBottom: "1px solid #f7f7f7",
            pb: 2,
          }}
        >
          <IconButton
            onClick={() => component.updateCart(product, 0)}
            sx={{
              position: "absolute",
              right: 10,
              top: 5,
              fontSize: "16px",
              color: "#000",
            }}
          >
            <i className="fa-regular fa-xmark" />
          </IconButton>
          <Box
            sx={{
              alignItems: "flex-start",
              display: "flex",
              p: 0,
            }}
          >
            <Box
              sx={{
                minWidth: "110px",
                height: "110px",
                width: "110px",
                marginRight: { xs: "16px", sm: "20px" },
                display: "flex",
                position: "relative",
              }}
            >
              {!component.getData("default.imageReady", false) && (
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100%"
                  sx={{ borderRadius: "8px" }}
                />
              )}
              <ButtonGroup
                sx={{
                  position: "absolute",
                  left: 0,
                  bottom: 0,
                  maxWidth: "100%",
                  backgroundColor: "rgb(244, 243, 241, 0.9)",
                  borderBottomLeftRadius: "6px",
                  borderBottomRightRadius: "6px",
                  borderTopRightRadius: "0px",
                  borderTopLeftRadius: "0px",
                  padding: "2px",
                  ".MuiButtonGroup-grouped": {
                    minWidth: 0,
                    width: "45px",
                    border: "none",
                    color: "#000",
                    padding: "4px 8px",
                    "&:hover": {
                      border: "none",
                      opacity: 0.8,
                      backgroundColor: "transparent",
                    },
                    "&:disabled": {
                      border: "none",
                      color: "#7d7d7d",
                    },
                  },
                }}
              >
                <Button
                  onClick={() =>
                    component.updateCart(
                      product,
                      devHelper.getObjectValue(product, "amount") - 1
                    )
                  }
                  disabled={devHelper.getObjectValue(product, "amount") === 1}
                  disableRipple
                >
                  <i className="fa-solid fa-minus"></i>
                </Button>
                <Button
                  disabled
                  sx={{
                    "&:disabled": {
                      border: "none",
                      p: 0,
                    },
                  }}
                >
                  <Avatar
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#000",
                      color: "#fff",
                      fontSize: "12px",
                    }}
                  >
                    {devHelper.getObjectValue(product, "amount")}
                  </Avatar>
                </Button>
                <Button
                  onClick={() =>
                    component.updateCart(
                      product,
                      devHelper.getObjectValue(product, "amount") + 1
                    )
                  }
                  disableRipple
                >
                  <i className="fa-solid fa-plus"></i>
                </Button>
              </ButtonGroup>
              <CardMedia
                component="img"
                alt={devHelper.getObjectValue(product, "item.name") + " image"}
                sx={{
                  maxWidth: "100%",
                  borderRadius: "12px",
                }}
                src={`${filesUrl}/products/images/${devHelper.getObjectValue(
                  product,
                  "item.slug"
                )}.png?v=${img_version}`}
                onError={(e) => {
                  e.target.src = `${filesUrl}/assets/images/not-found.png`;
                }}
                onLoad={() => {
                  component.setData({ "default.imageReady": true });
                }}
              />
            </Box>
            <ListItemText
              sx={{ m: 0, pr: 2 }}
              primary={
                <span
                  style={{
                    fontSize: "14px",
                    fontWeight: 600,
                    mb: 1,
                  }}
                >
                  <Translate
                    entity="item"
                    eslug={devHelper.getObjectValue(product, "item.slug")}
                    scope="default"
                    slug="name"
                    auto
                  />
                </span>
              }
              secondary={
                <>
                  <p className="line-clamp" style={{ margin: 0 }}>
                    {product.values.ingredients.map((slug, index) => {
                      return (
                        <React.Fragment key={`${slug}-${index}`}>
                          <Translate
                            entity="item"
                            eslug={slug}
                            scope="default"
                            slug="name"
                            auto
                          />
                          {index + 1 !== product.values.ingredients.length &&
                            ", "}
                        </React.Fragment>
                      );
                    })}
                    {product.items.map((item, index) => {
                      const { slug } = item;

                      return (
                        <React.Fragment key={`${slug}-${index}`}>
                          <Translate
                            entity="item"
                            eslug={slug}
                            scope="default"
                            slug="name"
                            auto
                          />
                          {index + 1 !== product.items.length && ", "}
                        </React.Fragment>
                      );
                    })}
                  </p>
                  <Typography
                    sx={{
                      mt: 1,
                      fontSize: "14px",
                      fontWeight: 500,
                      color: "rgba(0, 0, 0, 0.9)",
                      display: "block",
                    }}
                  >
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "price") *
                          devHelper.getObjectValue(product, "amount")
                      )}
                    {/* <IconButton
                      onClick={() => component.toggleProductDialog(product)}
                      sx={{
                        marginLeft: 2,
                        fontSize: 14,
                      }}
                    >
                      <i className="fa-duotone fa-pen-to-square" />
                    </IconButton> */}
                  </Typography>
                </>
              }
              secondaryTypographyProps={{ sx: { mt: 1 }, component: "div" }}
            />
          </Box>
        </ListItem>
      )}
    </>
  );
}
