export default function EpayForm(props) {
  return (
    <form
      id="epay-redirection-form"
      action="https://paycenter.piraeusbank.gr/redirection/pay.aspx"
      method="POST"
    >
      <input name="AcquirerId" type="hidden" value="" />
      <input name="MerchantId" type="hidden" value="" />
      <input name="PosId" type="hidden" value="" />
      <input name="User" type="hidden" value="" />
      <input name="LanguageCode" type="hidden" value="" />
      <input name="MerchantReference" type="hidden" value="" />
      <input name="ParamBackLink" type="hidden" value="" />
      <input type="submit" hidden value="Check out" />
    </form>
  );
}
