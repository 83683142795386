import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/home/products";
import Services from "../../Services/Services";

export default class Products extends Component {
  id = "products";
  group = "order";

  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);

    this.fetchOrder();
  }

  async fetchOrder() {
    const [orderService] = await Services.get("order");

    // Services.get("order").then(([orderService]) => {
    try {
      const response = await orderService.fetchOrder();
      // .then((orderService) => {
      //   const order = orderService.getData("order");
      //   this.setData({ "default.order": order });
      // })
      // .catch((orderService) => {});
      // });

      const order = response.getOrder();
      this.setData({ "default.order": order });
    } catch (e) {
      //
    }

    this.fetchDeals();
    this.fetchTypes();
    this.fetchProducts();
    this.fetchIngredientTypes();
  }

  async fetchOrderSpot(order) {
    const [hookService] = await Services.get("hook");
    const { spot } = order;

    if (spot) {
      const { _id } = spot;

      try {
        const response = await hookService.exec("spot", {
          params: {
            id: _id,
          },
        });

        if (response.isValid()) {
          this.setData({ "default.spot": response.getData() });
        }
      } catch (e) {
        //
      }
    }
  }

  fetchDeals() {
    Services.get("hook").then(([service]) => {
      service.exec("deals").then((response) => {
        const data = response.getData() || {};

        this.setData({
          "default.deals": data,
        });
      });
    });

    return this;
  }

  fetchTypes() {
    Services.get("hook").then(([service]) => {
      service.exec("types").then((response) => {
        const data = response.getData() || [];

        this.setData({
          "default.types": data,
        });
      });
    });

    return this;
  }

  fetchProducts() {
    Services.get("hook,order").then(([hookService, orderService]) => {
      const order = orderService.getOrder();
      const params = {};

      if (order) {
        const { spot } = order;

        if (spot) {
          params.spotId = spot._id;
        }
      }

      hookService
        .exec("products", {
          params,
        })
        .then((response) => {
          const data = response.getData() || {};
          this.setData({
            "default.products": data,
          });
        });

      if (order) {
        this.fetchOrderSpot(order);
      }
    });

    return this;
  }

  fetchIngredientTypes() {
    Services.get("hook").then(([service]) => {
      service.exec("ingredient-types").then((response) => {
        const data = response.getData() || [];
        this.setData({
          "default.ingredient_types": data,
        });
      });
    });

    return this;
  }

  isSpotOpened() {
    const { _values = {} } = this.getData("default.spot", {});
    const { opened = true } = _values;
    return opened;
  }
}
