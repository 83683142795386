import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/product_dialog";
import Services from "../../../Services/Services";

export default class ProductDialog extends Component {
  id = "product-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });
  }

  setProduct(product, ingredient_types) {
    if (!product.amount) {
      product.amount = 1;
    }

    if (!product.item) {
      product.item = {
        ...{ ...product._values },
        ...{ id: product._id },
      };
      product.price = product._values.price;
      product.special_price = product._values.special_price
        ? product._values.special_price
        : 0;
    }

    this.setData({
      "default.product": product,
      "default.ingredient_types": ingredient_types,
      "default.open": true,
    });

    this.getApp().sendToGA4(product, "view_item");

    window.location.href = "#product";

    window.onhashchange = () => {
      if (!window.location.hash) {
        this.setData({ "default.open": false });
        window.onhashchange = null;
      }
    };
  }

  closeDialog() {
    // this.setData({
    //   "default.open": false,
    // });
    window.location.href = "#";
  }

  handleClick(product, qnt) {
    const isAuth = this.isAuthenticated();

    Services.get("order").then(([orderService]) => {
      orderService
        .fetchOrder()
        .then((orderService) => {
          const order = orderService.getData("order");

          var comp_id = null;

          if (isAuth) {
            if (order) {
              var { shipping_address, delivery_type, spot } = order;
              if (!delivery_type) {
                comp_id = "delivery-type-dialog";
              } else {
                if (delivery_type === "delivery" && !shipping_address) {
                  comp_id = "select-address-dialog";
                } else if (delivery_type === "takeaway" && !spot) {
                  comp_id = "select-spot-dialog";
                } else {
                  this.updateCart(product, qnt);
                }
              }
            } else {
              comp_id = "delivery-type-dialog";
            }
          } else {
            comp_id = "login-register-dialog";
          }

          this.setData({ "default.open": false });

          if (comp_id) {
            this.getComponents()
              .findById(comp_id)
              .forEach((comp) => {
                comp.setData({
                  "default.open": true,
                });
                if (
                  typeof delivery_type !== "undefined" &&
                  delivery_type === "delivery"
                ) {
                  comp.retrieveAddresses();
                  comp.initGoogleAutocomplete("shipping_address");
                }
              });
          }
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });
  }

  updateIngredients(product, ingredient_slug, ingredient_type) {
    var ingredient_type_slug = ingredient_type._values.slug;
    var multiple = ingredient_type._values.multiple;

    if (multiple) {
      var ingredient = product.item.ingredients.find(
        (ingredient) => ingredient.item._values.slug === ingredient_slug
      );

      ingredient.selected = !ingredient.selected;

      product.item.price = ingredient.selected
        ? product.item.price + ingredient.price
        : product.item.price - ingredient.price;
      if (product.item.special_price > 0) {
        product.item.special_price = ingredient.selected
          ? product.item.special_price + ingredient.price
          : product.item.special_price - ingredient.price;
      }
    } else {
      var ingredients = product.item.ingredients.filter((ingredient) =>
        ingredient.item._values.ingredient_type.some(
          (type) => type.item._values.slug === ingredient_type_slug
        )
      );
      ingredients.forEach((ingredient) => {
        if (ingredient.item._values.slug === ingredient_slug) {
          if (!ingredient.selected) {
            product.item.price = product.item.price + ingredient.price;
            if (product.item.special_price > 0) {
              product.item.special_price =
                product.item.special_price + ingredient.price;
            }
          }
          ingredient.selected = true;
        } else {
          if (ingredient.selected) {
            product.item.price = product.item.price - ingredient.price;
            if (product.item.special_price > 0) {
              product.item.special_price =
                product.item.special_price - ingredient.price;
            }
          }
          ingredient.selected = false;
        }
      });
    }
    product.price = product.item.price;
    product.special_price = product.item.special_price;
    this.setData({
      "default.product": product,
    });
  }

  async updateCart(product) {
    const [orderService] = await Services.get("order");

    let { instance, amount } = product;
    const { id, ingredients = {} } = product.item;

    if (!instance) {
      instance = new Date().getTime();
    }

    if (amount) {
      const foundProduct = this.findInstance(orderService.getOrder(), product);

      if (foundProduct) {
        amount += foundProduct.amount;

        await this.updateCart({ ...foundProduct, ...{ amount: 0 } });
      }
    }

    const newIngredients = ingredients
      .filter((ingredient) => ingredient.selected)
      .map((ingredient) => ingredient.item._values.slug);

    await orderService.updateItem({
      itemId: id,
      itemType: "product",
      itemInstance: instance,
      values: {
        ingredients: newIngredients,
      },
      amount,
    });

    if (amount !== 0) {
      this.getApp().sendToGA4({ ...product }, "add_to_cart", amount);
    }

    this.getComponents()
      .findByGroup("order")
      .forEach((order) => {
        order.setData({ "default.order": orderService.getOrder() });
      });

    this.getComponents()
      .findById("main-message")
      .first()
      .setData({
        "success-message": this.trans("product-updated-message"),
      });
  }

  findInstance(order, newProduct) {
    const newProductId = newProduct.item.id;
    const newProductInstance = newProduct.instance;
    const newProductIngredients = (
      newProduct._values
        ? newProduct.item.ingredients
        : newProduct.item.ingredients
    )
      .filter(({ selected }) => selected)
      .map(({ item }) => item._values.slug);

    const { items = [] } = order;

    let orderItem = items.find((orderItem) => {
      return (
        orderItem.item.id === newProductId &&
        orderItem.instance !== newProductInstance &&
        orderItem.values.ingredients.length === newProductIngredients.length &&
        orderItem.values.ingredients.filter(
          (orderItemIngredient) =>
            !newProductIngredients.includes(orderItemIngredient)
        ).length === 0
      );
    });

    // if (orderItem) {
    //   const orderItemIngredients = orderItem.values.ingredients;

    //   orderItem =
    //     orderItemIngredients.filter(
    //       (orderItemIngredient) =>
    //         !newProductIngredients.includes(orderItemIngredient)
    //     ).length === 0
    //       ? orderItem
    //       : null;
    // }

    return orderItem;
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.setData({ "default.open": false });
          this.getComponents()
            .findById("delivery-type-dialog")
            .first()
            .setData({
              "default.open": true,
            });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
