import ServicesDefault from "./Default/Services";
import Helpers from "../../modules/Core/Helpers/Helpers";

const Services = {
  default: ServicesDefault,
};

const ExportDefault = {
  get: function (services) {
    const env = Helpers.Env.get("env_service", "default");

    services = services
      .split(",")
      .map((service) =>
        service
          .split("-")
          .map((name) => {
            return name.charAt(0).toUpperCase() + name.slice(1);
          })
          .join("")
      )
      .map((service) => {
        const Service = Services[env][service];
        return Service ? Service : null;
      });

    return new Promise((resolve, reject) => {
      resolve(services);
    });
  },
};

export default ExportDefault;
