import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/login";
import Services from "../../Services/Services";

export default class Login extends Component {
  template = templateDefault;

  googleAuthLoaded = false;

  onLoad(data) {
    super.onLoad(data);

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadFB({ window, document, page: this.getPage() });
    });

    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loadGoogle({ window, document, page: this.getPage() });
    });
  }

  loginFB() {
    this.deleteOrder();
    Services.get("socialLogin").then(([socialLogin]) => {
      socialLogin.loginFB({ window, document, page: this.getPage() });
    });
  }

  deleteOrder() {
    Services.get("order").then(([orderService]) => {
      orderService.delete();
    });
  }

  login(e) {
    e.preventDefault();

    const { username, password } = this.getData("default.fields.auth", {});
    const error = {};

    if (!username) {
      error["username"] = this.ucfirst("email-helpertext");
    } else {
      if (!this.getHelpers("validate").email(username, {})) {
        error["username"] = this.ucfirst("invalid-email");
      }
    }

    if (!password) {
      error["password"] = this.ucfirst("password-helpertext");
    }

    this.setData({
      "error.fields.auth": error,
    });

    if (Object.keys(error).length === 0) {
      Services.get("auth").then(([authService]) => {
        authService
          .login({ username, password })
          .then((authService) => {
            this.getHelpers("Auth").set(
              authService.getData("_response").getData()
            );

            this.getComponents()
              .findById("login-register-dialog")
              .first()
              .setData({
                "default.open": false,
              });

            this.getPage().redirectToRedirect("/");
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": authService.getData("_response").getMessage(),
              });
          });
      });
    }
  }
}
