import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/product_list_view";
import Services from "../../Services/Services";
import OrderService from "../../Services/Default/Order";

export default class ProductListView extends Component {
  id = "procut-list-view";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }

  toggleProductDialog() {
    let { product = {}, ingredient_types = [] } = { ...this.props };

    if (this.isDeal(product)) {
      this.getComponents()
        .findById("deal-dialog")
        .first()
        .setProduct(JSON.parse(JSON.stringify(product)));
    } else {
      this.getComponents()
        .findById("product-dialog")
        .first()
        .setProduct(JSON.parse(JSON.stringify(product)), ingredient_types);
    }
  }

  isDeal(product) {
    let { _values } = product;
    let { types = [] } = _values;
    let type = "default";

    if (types.length) {
      type = types[0].item._values.slug;
    }

    return type === "deal";
  }

  handleClick() {
    const isAuth = this.isAuthenticated();

    if (isAuth) {
      Services.get("order").then(([orderService]) => {
        orderService
          .fetchOrder()
          .then((orderService) => {
            const order = orderService.getData("order");
            // const { shipping_address, delivery_type } = order;
            const { spot, delivery_type } = order;
            if (spot) {
              this.toggleProductDialog();
            } else {
              switch (delivery_type) {
                case "delivery": {
                  this.getComponents()
                    .findById("delivery-type-dialog")
                    .first()
                    .setData({
                      "default.open": true,
                    });
                  break;
                }
                case "takeaway": {
                  this.getComponents()
                    .findById("delivery-type-dialog")
                    .first()
                    .setData({
                      "default.open": true,
                    });
                  break;
                }
                default:
                  break;
              }
            }
          })
          .catch((orderService) => {
            this.checkOrderError(orderService);
          });
      });
    } else {
      this.getComponents().findById("login-register-dialog").first().setData({
        "default.open": true,
      });
    }
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          // this.getComponents()
          //   .findById("main-message")
          //   .first()
          //   .setData({
          //     "error-message": this.trans(type),
          //   });
          this.getComponents()
            .findById("delivery-type-dialog")
            .first()
            .setData({
              "default.open": true,
            });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }

  isInstock() {
    let inStock = true;
    let { product = {} } = { ...this.props };
    const { _values = {} } = product;
    const { spots = [] } = _values;

    const order = OrderService.getOrder();

    if (order) {
      const { spot } = order;

      if (spot) {
        inStock = spots.find(
          ({ stock = false, item }) => item._id === spot._id && stock
        );
      }
    }

    return inStock;
  }

  isSpotOpened() {
    const products = this.getComponents().findById("products").first();
    return products ? products.isSpotOpened() : true;
  }
}
