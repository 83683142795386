import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { styled } from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  IconButton,
  OutlinedInput,
  Container,
  InputLabel,
  InputAdornment,
  FormControl,
  FormHelperText,
  Link,
  Box,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginRegisterBtn = styled(Button)(({ theme }) => ({
  margin: theme.spacing(2, 0),
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "12px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const MyTextField = styled(TextField)(({ theme }) => ({
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

const PasswordField = styled(FormControl)(({ theme }) => ({
  marginTop: "16px",
  marginBottom: "8px",
  width: "100%",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

export default function Register(props) {
  const { component, dialog } = props;

  const [values, setValues] = React.useState({
    password: "",
    confirm_password: "",
    showPassword: false,
  });

  const handleClickShowPassword = () => {
    setValues({ ...values, showPassword: !values.showPassword });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleRegister = (event) => {
    component.register(event);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        px: { xs: 1, sm: 2 },
      }}
    >
      <Box>
        <LoginRegisterBtn
          type="submit"
          fullWidth
          sx={{
            borderRadius: "4px",
            height: "40px",
            textTransform: "none",
            bgcolor: "transparent",
            border: "1px solid #dadce0",
            color: "#3c4043",
            px: 1,
            "&:hover": {
              background: "rgba(66,133,244,.04)",
              borderColor: "#d2e3fc",
            },
            "& i": {
              fontSize: "20px",
              color: "#3b5998",
            },
          }}
          onClick={() => component.signinFB()}
          startIcon={<i className="fa-brands fa-facebook-f" />}
        >
          {component.ucfirst("register-facebook-btn")}
        </LoginRegisterBtn>
        <Box
          id="google-sign-in-btn"
          type="submit"
          fullWidth
          variant="contained"
        >
          <i
            className="fa-brands fa-google"
            style={{ fontSize: "20px", marginRight: "10px" }}
          ></i>
          {component.ucfirst("register-google-btn")}
        </Box>
      </Box>
      <div
        style={{
          marginTop: 16,
          paddingTop: 16,
          borderTop: "1px solid rgba(0,0,0,.1)",
          width: "100%",
          textAlign: "center",
          position: "relative",
        }}
      >
        <Typography
          variant="body1"
          sx={{
            zIndex: 1,
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50px",
            color: "#7d7d7d",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            fontSize: "18px",
            padding: "0px 5px",
            width: "30px",
            height: "30px",
          }}
        >
          {component.trans("or")}
        </Typography>
      </div>
      <form style={{ width: "100%", marginTop: 8 }} onSubmit={handleRegister}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="name"
          label={component.ucfirst("fullname-textfield")}
          name="name"
          autoComplete="name"
          helperText={component.getData("error.fields.name")}
          value={component.getData("default.fields.name", "")}
          onChange={(e) => {
            component.setData({ "default.fields.name": e.target.value });
            component.deleteData("error.fields.name");
          }}
          error={component.dataExists("error.fields.name")}
        />

        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label={component.ucfirst("email-textfield")}
          name="email"
          autoComplete="email"
          helperText={component.getData("error.fields.username")}
          value={component.getData("default.fields.username", "")}
          onChange={(e) => {
            component.setData({ "default.fields.username": e.target.value });
            component.deleteData("error.fields.username");
          }}
          error={component.dataExists("error.fields.username")}
        />
        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.password")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.password", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.password": e.target.value,
              });
              component.deleteData("error.fields.password");
            }}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                  disableRipple
                  sx={{
                    "&:hover, &:focus": { backgroundColor: "transparent" },
                  }}
                >
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
            label={component.ucfirst("password-textfield")}
          />
          {component.dataExists("error.fields.password") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.password")}
            </FormHelperText>
          )}
        </PasswordField>

        <PasswordField
          variant="outlined"
          error={component.dataExists("error.fields.verify")}
        >
          <InputLabel htmlFor="outlined-adornment-password">
            {component.ucfirst("confirm-password-textfield")}
          </InputLabel>
          <OutlinedInput
            type={values.showPassword ? "text" : "password"}
            value={component.getData("default.fields.verify", "")}
            onChange={(e) => {
              component.setData({
                "default.fields.verify": e.target.value,
              });
              component.deleteData("error.fields.verify");
            }}
            label={component.ucfirst("confirm-password-textfield")}
          />
          {component.dataExists("error.fields.verify") && (
            <FormHelperText error style={{ margin: "3px 14px 0" }}>
              {component.getData("error.fields.verify")}
            </FormHelperText>
          )}
        </PasswordField>
        <LoginRegisterBtn type="submit" fullWidth variant="contained">
          {component.ucfirst("register-btn")}
        </LoginRegisterBtn>
        {dialog && (
          <div
            style={{ textAlign: "center", width: "100%", marginBottom: "16px" }}
          >
            <Link
              underline="hover"
              type="button"
              component={RouterLink}
              to="/signin"
              sx={{
                color: "#7d7d7d",
              }}
            >
              {component.ucfirst("back-to-signin")}
            </Link>
          </div>
        )}
      </form>
    </Container>
  );
}
