import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/delivery_type_dialog";
import Services from "../../../Services/Services";

export default class DeliveryTypeDialog extends Component {
  id = "delivery-type-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });
  }

  updateDeliveryType(type) {
    Services.get("order").then(async ([orderService]) => {
      orderService
        .updateDeliveryType({
          type,
        })
        .then((orderService) => {
          const order = orderService.getOrder();
          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });
          this.setData({
            "default.open": false,
          });
          this.showDialog(type);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  showDialog(type) {
    var comp_id =
      type === "delivery" ? "select-address-dialog" : "select-spot-dialog";

    this.getComponents()
      .findById(comp_id)
      .forEach((comp) => {
        comp.setData({
          "default.open": true,
        });
        if (type === "delivery") {
          setTimeout(() => {
            comp.retrieveAddresses();
            comp.initGoogleAutocomplete("shipping_address");
          }, 500);
        }
      });
  }
}
