import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/checkout/product_checkout_view";
// import Services from "../../Services/Services";

export default class ProductCheckoutView extends Component {
  id = "procut-checkout-cart-view";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setProducts();
  }

  setProducts() {
    var product = { ...this.props.product };

    this.setData({ "default.product": product });
  }
}
