import React from "react";
import { styled } from "@mui/material/styles";
import {
  Typography,
  Paper,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
} from "@mui/material";
import EpayForm from "./epay_form";

const RadioBtn = styled(Radio)(({ theme }) => ({
  color: "#f05d32",
  "&.Mui-checked": {
    color: "#f05d32",
    "&:hover": {
      background: "transparent",
    },
  },
  "&:hover": {
    background: "transparent",
  },
}));

export default function Checkout(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null } = component.getData("default", {});

  const paymentTypes = component.getData(
    "default.order.spot.payment_types",
    []
  );

  const icons = {
    cash: "fa-solid fa-wallet",
    "epay-credit": "fa-regular fa-credit-card",
  };

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: "12px",
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              fontSize: { xs: "20px", sm: "24px", md: "28px" },
              lineHeight: 1,
              mb: 2,
            }}
          >
            {component.ucfirst("checkout-title-3")}
          </Typography>
          <RadioGroup
            name="payment_method"
            value={component.findPaymentTypeSelected()}
          >
            {paymentTypes
              .filter((paymentType) => paymentType.active)
              .map((paymentType, index) => {
                const { item } = paymentType;
                const { _values } = item;
                const slug = devHelper.getObjectValue(_values, "slug");

                return (
                  <FormControlLabel
                    key={slug}
                    value={devHelper.getObjectValue(item, "_id")}
                    control={<RadioBtn disableRipple />}
                    onClick={() =>
                      component.updatePaymentType(
                        devHelper.getObjectValue(item, "_id")
                      )
                    }
                    label={
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          fontSize: 16,
                          fontWeight: 500,
                          "& div": {
                            textAlign: "center",
                            display: "inline-flex",
                          },
                          "& i": {
                            minWidth: 30,
                            ml: 1,
                            fontSize: 20,
                          },
                        }}
                      >
                        {component.ucfirst(
                          devHelper.getObjectValue(_values, "slug")
                        )}
                        <Box>
                          <i className={icons[slug] || icons.cash} />
                        </Box>
                      </Box>
                    }
                  />
                );
              })}
          </RadioGroup>
        </Paper>
      )}
      <EpayForm {...props} />
    </>
  );
}
