import Page from "../../../../modules/Core/Components/Page";
import templateDefault from "../../../templates/default/pages/user/order";

export default class Order extends Page {
  template = templateDefault;
  fullAuth = true;

  onAuth(data) {
    const { auth } = data;
    if (!auth.isAuthenticated()) {
      this.redirectTo("/");
    }
  }

  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("order")} | ${this.trans("window-title")}`
    );
    this.scrollToTop();
  }
}
