import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/navbar";

export default class Navbar extends Component {
  id = "navbar";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }
}
