import React from "react";
import Box from "@mui/material/Box";
import CheckoutNavbar from "../../../Components/Widgets/CheckoutNavbar";
import Checkout from "../../../Components/Widgets/Checkout";

const ExportDefault = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
      }}
    >
      <CheckoutNavbar {...props} />
      <Checkout {...props} />
    </Box>
  );
};

export default ExportDefault;
