import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/terms_and_conditions";

export default class TermsAndConditions extends Page {
  template = templateDefault;

  onAuth() {
    this.refresh();
  }

  onLoad(data) {
    super.onLoad(data);
    this.scrollToTop();
  }

  onUpdate(prevProps, prevState, snapshot) {
    super.onUpdate(prevProps, prevState, snapshot);

    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.getComponents()
        .findById("terms-and-conditions")
        .first()
        .setSection();
      this.scrollToTop();
    }
  }
}
