import Page from "../../../modules/Core/Components/Page";
import templateDefault from "../../templates/default/pages/checkout";

export default class Checkout extends Page {
  title = "checkout-page";

  template = templateDefault;

  onAuth(data) {
    const { auth } = data;
    if (!auth.isAuthenticated()) {
      this.redirectTo("/");
    }
  }
  onLoad(data) {
    super.onLoad(data);
    this.getApp().updateWindowTitle(
      `${this.ucfirst("Checkout")} | ${this.trans("window-title")}`
    );
    this.scrollToTop();
  }
}
