import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/login_register_dialog";

export default class LoginRegisterDialog extends Component {
  id = "login-register-dialog";

  template = templateDefault;

  googleAuthLoaded = false;

  onLoad(data) {
    super.onLoad(data);

    // this.setData({
    //   "default.open": false,
    // });

    this.closeDialog();
  }

  openDialog() {
    this.setData({
      "default.open": true,
    });

    return this;
  }

  closeDialog() {
    this.setData({
      "default.open": false,
    });

    return this;
  }
}
