import Helpers from "../../../modules/Core/Helpers/Helpers";
import MainApi from "../../Api/Main";
import Service from "../Service";

class Product extends Service {
  getAll(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "product", {
        params,
      }).then((r) => {
        const result = this.evaluate(r, "products");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getOne(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      slug = "",
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.item(account, repository, "product", slug).then((r) => {
        const result = this.evaluate(r, "product");
        if (result) {
          resolve({ service: this, response: r });
        } else {
          reject(this);
        }
      });
    });
  }

  getAllReceipes(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "receipe", {
        params,
      }).then((r) => {
        const result = this.evaluate(r, "receipes");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getAllSliders(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "slider", {
        params,
      }).then((r) => {
        const result = this.evaluate(r, "sliders");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getAllMainSliders(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "main_slider", {
        params,
      }).then((r) => {
        const result = this.evaluate(r, "main_sliders");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }

  getAllTiles(options) {
    const {
      account = Helpers.Env.getDefaultAccount(),
      repository = Helpers.Env.getDefaultRepository(),
      params = {},
    } = options || {};

    return new Promise((resolve, reject) => {
      MainApi.items(account, repository, "tile", {
        params,
      }).then((r) => {
        const result = this.evaluate(r, "tiles");

        if (result) {
          resolve(this);
        } else {
          reject(this);
        }
      });
    });
  }
}

export default new Product();
