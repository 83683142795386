import React from "react";
import { styled } from "@mui/material/styles";
import {
  Container,
  Button,
  Typography,
  Grid,
  Paper,
  CircularProgress,
  Popover,
  Box,
} from "@mui/material";
import CouponDialog from "../../../../Components/Widgets/Dialogs/CouponDialog";
import Translate from "../../../../../modules/Core/Components/Translate";

const AddCouponBtn = styled(Button)(({ theme }) => ({
  background: "transparent",
  border: "1px solid #000",
  borderRadius: "12px",
  color: "#000",
  height: 48,
  textTransform: "none",
  "&:hover": {
    border: "1px solid #000",
    background: "#fff",
  },
}));

const Coupon = styled(Paper)(({ theme }) => ({
  border: "1px solid #f05d32",
  borderRadius: "10px",
  height: "160px",
  backgroundColor: "#f05d32",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
}));

export default function Coupons(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");
  const coupons = component.getData("default.coupons", null);
  const [newCouponDialogOpen, setNewCouponDialogOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [c_slug, setCouponSlug] = React.useState(null);

  const handleClick = (event, slug) => {
    setAnchorEl(event.currentTarget);
    setCouponSlug(slug);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
      maxWidth="lg"
    >
      <Box
        sx={{
          borderTop: "2px solid #f05d32",
          width: "100%",
          textAlign: "center",
          position: "relative",
          zIndex: -1,
          my: 6,
        }}
      >
        <Typography
          variant="h1"
          sx={{
            zIndex: 1,
            position: "absolute",
            top: "0px",
            left: "50%",
            transform: "translate(-50%, -50%)",
            borderRadius: "50px",
            color: "#000",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            px: 4,
            fontSize: "35px",
            fontWeight: 400,
          }}
        >
          {component.ucfirst("coupons-title")}
        </Typography>
      </Box>
      <AddCouponBtn
        variant="outlined"
        onClick={() => {
          setNewCouponDialogOpen(true);
        }}
      >
        <i
          className="fa-solid fa-plus"
          style={{ marginRight: "5px", fontSize: "18px" }}
        ></i>
        {component.trans("add-new-coupon-btn")}
      </AddCouponBtn>
      {!coupons ? (
        <CircularProgress
          style={{
            margin: "80px 0",
            color: "#0E2431",
          }}
        />
      ) : (
        <>
          {coupons.length !== 0 ? (
            <>
              <Grid
                container
                alignItems="center"
                sx={{ mt: 3, mb: 2 }}
                direction="row"
                spacing={2}
              >
                {coupons.map((coupon) => (
                  <Grid
                    item
                    lg={3}
                    md={4}
                    sm={6}
                    xs={12}
                    key={devHelper.getObjectValue(coupon, "_id")}
                  >
                    <Coupon
                      elevation={2}
                      onClick={(e) =>
                        handleClick(e, devHelper.getObjectValue(coupon, "slug"))
                      }
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          color: "#fff",
                          fontSize: 18,
                          mr: 1,
                          mt: 0.5,
                        }}
                        onClick={(e) =>
                          handleClick(
                            e,
                            devHelper.getObjectValue(coupon, "slug")
                          )
                        }
                      >
                        <i className="fa-light fa-circle-info" />
                      </Box>
                      <Typography
                        align="center"
                        sx={{
                          color: "#fff",
                          fontSize: "60px",
                        }}
                      >
                        {devHelper.getObjectValue(coupon, "name")}
                      </Typography>
                    </Coupon>
                  </Grid>
                ))}
              </Grid>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: { xs: "left", md: "right" },
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                sx={{ visibility: c_slug ? "visible" : "hidden" }}
              >
                <Typography sx={{ p: 1 }}>
                  <Translate
                    entity="item"
                    eslug={c_slug}
                    scope="default"
                    slug="info"
                    auto
                  />
                </Typography>
              </Popover>
            </>
          ) : (
            <div
              style={{
                textAlign: "center",
                margin: "100px 0",
              }}
            >
              <Typography>{component.ucfirst("zero-coupons")}</Typography>
            </div>
          )}
        </>
      )}
      <CouponDialog
        {...{
          ...props,
          ...{
            open: newCouponDialogOpen,
            setOpen: (boolean) => setNewCouponDialogOpen(boolean),
            retrieveCoupons: () => component.retrieveCoupons(),
          },
        }}
      />
    </Container>
  );
}
