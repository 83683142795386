import React from "react";
import {
  ListItem,
  ListItemButton,
  ListItemText,
  Box,
  Typography,
  CardMedia,
  Fade,
} from "@mui/material";
import Translate from "../../../../modules/Core/Components/Translate";

export default function Poduct_List_View(props) {
  const { component } = props;
  const { last = false, product = null } = component.props;
  const devHelper = component.getHelpers("dev");
  const exists = component.getData("default.imageExists", true);
  const isActive = component.isInstock() && component.isSpotOpened();
  const { price, special_price = 0 } = devHelper.getObjectValue(
    product,
    "_values"
  );
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <>
      {product && (
        <ListItem
          disablePadding
          sx={{
            borderBottom: last ? "none" : "1px solid #f7f7f7",
            pt: 2,
            pb: 2,
          }}
        >
          <ListItemButton
            sx={{
              alignItems: "flex-start",
              p: 0,
              "&:hover": { backgroundColor: "transparent" },
            }}
            onClick={() => {
              if (isActive) {
                component.handleClick(product);
              }
            }}
            disableRipple
          >
            <ListItemText
              sx={{ m: 0 }}
              primary={
                <Typography
                  component="h2"
                  sx={{
                    fontSize: "16px",
                    fontWeight: 600,
                    lineHeight: 1,
                    mb: 1,
                  }}
                >
                  <Translate
                    entity="item"
                    eslug={devHelper.getObjectValue(product, "_values.slug")}
                    scope="default"
                    slug="name"
                    auto
                  />
                </Typography>
              }
              secondary={
                <>
                  <Typography
                    component="h3"
                    className="line-clamp"
                    sx={{ fontSize: "14px", mb: 1 }}
                  >
                    <Translate
                      entity="item"
                      eslug={devHelper.getObjectValue(product, "_values.slug")}
                      scope="default"
                      slug="description"
                      auto
                    />
                  </Typography>
                  {price > 0 && (
                    <Box
                      component="span"
                      sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        color: "#000",
                      }}
                    >
                      {component
                        .getApp()
                        .priceFormater(
                          special_price > 0 ? special_price : price
                        )}
                    </Box>
                  )}
                  {special_price > 0 && (
                    <Box
                      component="span"
                      sx={{
                        ml: 1,
                        textDecoration: "line-through",
                        color: "#f05d32",
                      }}
                    >
                      <Box
                        component="span"
                        sx={{
                          fontSize: 14,
                          color: "#777",
                          fontWeight: 400,
                        }}
                      >
                        {component.getApp().priceFormater(price)}
                      </Box>
                    </Box>
                  )}
                </>
              }
              secondaryTypographyProps={{ sx: { mt: 1 }, component: "div" }}
            />
            <Box
              sx={{
                minWidth: "110px",
                height: "110px",
                width: "110px",
                ml: { xs: 1, sm: 2 },
                display: exists ? "flex" : "none",
                position: "relative",
              }}
            >
              <Fade in={component.getData("default.imageReady", false)}>
                <CardMedia
                  component="img"
                  alt={devHelper.getObjectValue(product, "_values.name")}
                  sx={{
                    maxWidth: "100%",
                    borderRadius: "12px",
                    webkitFilter: isActive
                      ? "grayscale(0%)"
                      : "grayscale(100%)" /* Safari 6.0 - 9.0 */,
                    filter: isActive ? "grayscale(0%)" : "grayscale(100%)",
                  }}
                  src={`${filesUrl}/products/images/${devHelper.getObjectValue(
                    product,
                    "_values.slug"
                  )}.png?v=${img_version}`}
                  onLoad={() => {
                    component.setData({ "default.imageReady": true });
                  }}
                  onError={() => {
                    component.setData({ "default.imageExists": false });
                  }}
                />
              </Fade>
            </Box>
          </ListItemButton>
        </ListItem>
      )}
    </>
  );
}
