import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/reset_password_dialog";
import Services from "../../../Services/Services";

export default class ResetPasswordDialog extends Component {
  id = "reset-password-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
  }

  resetPassword(e) {
    if (e) e.preventDefault();

    const { password, verify } = this.getData("default.fields", {});
    const error = {
      fields: {},
      message: "",
    };

    const key = this.getPage().getQuery("resetPassword", "");

    if (!password) {
      error.fields["password"] = this.ucfirst("password-helpertext");
    }

    if (!verify) {
      error.fields["verify"] = this.ucfirst("confirm-password-helpertext");
    }

    if (password && verify && password !== verify) {
      this.getComponents()
        .findById("main-message")
        .first()
        .setData({
          "error-message": this.ucfirst("invalid-password-verification"),
        });
      error.message = this.ucfirst("invalid-password-verification");
    }

    if (!key) {
      error.fields["key"] = "";
    }

    this.setData({
      error,
    });

    if (Object.keys(error.fields).length === 0 && !error.message) {
      Services.get("auth", "test").then(([authService]) => {
        authService
          .passwordReset({ password, verify, key })
          .then((authService) => {
            this.getHelpers("Auth").set(
              authService.getData("_response").getData()
            );
            this.getPage().redirectToRedirect("/");
          })
          .catch((authService) => {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": authService.getData("_response").getMessage(),
              });
          });
      });
    }
  }
}
