import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Pages from "../Components/Pages";
import MainMessage from "../Components/Widgets/MainMessage";
import LoginRegisterDialog from "../Components/Widgets/Dialogs/LoginRegisterDialog";
import DeliveryTypeDialog from "../Components/Widgets/Dialogs/DeliveryTypeDialog";
import SelectAddressDialog from "../Components/Widgets/Dialogs/SelectAddressDialog";
import SelectSpotDialog from "../Components/Widgets/Dialogs/SelectSpotDialog";
import OfferDialog from "../Components/Widgets/Dialogs/OfferDialog";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
  },
  palette: {
    primary: {
      main: "#000",
    },
    secondary: {
      main: "#000",
    },
  },
  appbar_height: {
    desktop: "64px",
    mobile: "56px",
  },
  slider_height: {
    desktop: "108px",
    mobile: "82px",
  },
});

const ExportDefault = (props) => {
  const { component } = props;

  return (
    component.isLoaded() && (
      <ThemeProvider theme={theme}>
        <Router>
          <CssBaseline />
          <MainMessage />
          <LoginRegisterDialog />
          <DeliveryTypeDialog />
          <SelectAddressDialog />
          <SelectSpotDialog />
          <OfferDialog />
          <Switch>
            <Route exact path="/" component={Pages.Home} />
            <Route exact path="/checkout" component={Pages.Checkout} />
            <Route
              exact
              path="/terms-and-conditions/:section"
              component={Pages.TermsAndConditions}
            />
            <Route exact path="/profile" component={Pages.Profile} />
            <Route exact path="/addresses" component={Pages.Addresses} />
            <Route exact path="/coupons" component={Pages.Coupons} />
            <Route exact path="/orders" component={Pages.Orders} />
            <Route exact path="/order/:id" component={Pages.Order} />
            <Route exact path="/epay-success" component={Pages.EpayResult} />
            <Route exact path="/epay-failure" component={Pages.EpayResult} />
            <Route component={Pages.Redirect} />
          </Switch>
        </Router>
      </ThemeProvider>
    )
  );
};

export default ExportDefault;
