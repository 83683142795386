import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Typography,
  List,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  CircularProgress,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import ProductCheckoutView from "../../../../Components/Widgets/ProductCheckoutView";
import Translate from "../../../../../modules/Core/Components/Translate";
import SendIcon from "@mui/icons-material/Send";

const MyTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: "none",
  fontSize: 16,
  paddingTop: 0,
}));

export default function SummarySection(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { order = null } = component.getData("default", {});

  return (
    <>
      {order && (
        <Paper
          elevation={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            px: { xs: 2, md: 3 },
            py: 2,
            borderRadius: "12px",
          }}
        >
          <Box style={{ width: "100%" }}>
            <Typography
              sx={{
                fontWeight: 500,
                fontSize: { xs: "20px", sm: "24px", md: "28px" },
                lineHeight: 1,
                mb: 2,
              }}
            >
              {component.ucfirst("checkout-title-4")}
            </Typography>
            {order && order.items.length !== 0 && (
              <Box>
                <List dense sx={{ width: "100%", justifyContent: "top" }}>
                  {order.items.map((product, index) => (
                    <ProductCheckoutView
                      key={index}
                      {...{
                        ...props,
                        ...{
                          product,
                        },
                      }}
                    />
                  ))}
                </List>
                <Table>
                  <TableBody>
                    <TableRow>
                      <MyTableCell
                        rowSpan={5}
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                      />
                      <MyTableCell
                        rowSpan={5}
                        sx={{ display: { xs: "none", md: "table-cell" } }}
                      />
                      <MyTableCell>{component.ucfirst("subtotal")}</MyTableCell>
                      <MyTableCell align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.items")
                          )}
                      </MyTableCell>
                    </TableRow>
                    {order.services
                      .filter((service) => service.selected)
                      .map((service) => (
                        <TableRow
                          key={devHelper.getObjectValue(service, "slug")}
                        >
                          <MyTableCell>
                            {component.ucfirst(
                              devHelper.getObjectValue(service, "slug")
                            )}
                          </MyTableCell>
                          <MyTableCell align="right">
                            {component
                              .getApp()
                              .priceFormater(
                                devHelper.getObjectValue(service, "price")
                              )}
                          </MyTableCell>
                        </TableRow>
                      ))}
                    {devHelper.getObjectValue(order, "totals.discount") ? (
                      <TableRow>
                        <MyTableCell>
                          {component.ucfirst("discount")}
                        </MyTableCell>
                        <MyTableCell align="right">
                          -
                          {component
                            .getApp()
                            .priceFormater(
                              devHelper.getObjectValue(order, "totals.discount")
                            )}
                        </MyTableCell>
                      </TableRow>
                    ) : (
                      <></>
                    )}
                    <TableRow>
                      <MyTableCell sx={{ fontWeight: 500 }}>
                        {component.ucfirst("total")}
                      </MyTableCell>
                      <MyTableCell sx={{ fontWeight: 500 }} align="right">
                        {component
                          .getApp()
                          .priceFormater(
                            devHelper.getObjectValue(order, "totals.grand")
                          )}
                      </MyTableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            )}

            <form
              onSubmit={(e) => component.complete(e)}
              style={{ textAlign: "center" }}
            >
              <Typography sx={{ textAlign: "center", mt: 3, fontSize: 14 }}>
                <Translate
                  entity="literal"
                  eslug="agree-privacy-policy-terms-checkbox"
                  scope="default"
                  slug="default"
                  auto
                />
              </Typography>
              <LoadingButton
                type="submit"
                fullWidth
                sx={{
                  mt: 1,
                  maxWidth: { xs: "90%", sm: "80%" },
                  mx: { xs: "5%", sm: "10%" },
                  padding: "12px 14px",
                  bgcolor: "#f05d32",
                  borderRadius: "12px",
                  border: "1px solid #f05d32",
                  color: "#fff",
                  "&:hover": {
                    bgcolor: "#f05d32",
                  },
                  "&:disabled": {
                    color: "#fff",
                    opacity: 0.8,
                  },
                }}
                endIcon={<SendIcon />}
                loadingPosition="end"
                loadingIndicator={
                  <CircularProgress size={16} sx={{ color: "#fff" }} />
                }
                loading={component.getData("default.preparing")}
              >
                {component.ucfirst("place-order-btn")}
              </LoadingButton>
            </form>
          </Box>
        </Paper>
      )}
    </>
  );
}
