import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/user/order";
import Services from "../../../Services/Services";

export default class Order extends Component {
  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);
    this.retrieveOrder();
  }

  retrieveOrder() {
    const orderId = this.getPage().getParam("id");

    Services.get("user").then(([userService]) => {
      userService
        .fetchOrder(orderId)
        .then((orderService) => {
          const order = orderService.getData("order");

          if (order) {
            this.setData({
              "default.order": order,
            });
          } else {
            this.redirectTo("/signin");
          }
        })
        .catch((err) => {
          console.log("error", err);
        });
    });
  }

  getOrdersId() {
    return this.getPage().getParam("id", "");
  }
}
