import * as React from "react";
import {
  Box,
  Typography,
  Container,
  Button,
  TextField,
  CardMedia,
} from "@mui/material";

export default function SubscriptionSection(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  return (
    <Box
      sx={{
        bgcolor: "#f4b571",
      }}
    >
      <Container maxWidth="lg" sx={{ px: 2, position: "relative" }}>
        <Box
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            component
              .getApp()
              .newsletterSubscribe(
                component.getData("default.subscribe.email")
              );

            component.setData({ "default.subscribe.email": "" });
          }}
          sx={{
            maxWidth: { xs: "unset", md: 700, lg: 750 },
            textAlign: { xs: "center", md: "left" },
            py: { xs: 2, md: 3 },
          }}
        >
          <Typography
            component="h1"
            sx={{
              mb: 1,
              fontSize: { xs: 22, sm: 26, md: 32 },
              fontWeight: 500,
              lineHeight: 1,
            }}
          >
            {component.ucfirst("subscribe-title")}
          </Typography>
          <Typography
            component="h2"
            sx={{
              fontSize: 16,
              mb: 4,
              display: { xs: "none", md: "block" },
            }}
          >
            {component.ucfirst("subscribe-info")}
          </Typography>
          <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            type="text"
            placeholder={component.ucfirst("email-holder")}
            onChange={(e) =>
              component.setData({
                "default.subscribe.email": e.target.value,
              })
            }
            value={component.getData("default.subscribe.email", "")}
            sx={{
              border: "none",
              maxWidth: { xs: 300, md: 350 },
              borderRadius: 30,
              mx: { xs: "auto", sm: 1, md: 2 },
              display: { xs: "block", sm: "inline-flex" },
              mt: { xs: 3, sm: 2 },
              mb: 0,
              fontSize: 16,
              bgcolor: "#fff",
              verticalAlign: "middle",
              "& label.Mui-focused": {
                color: "#000",
                borderRadius: 30,
              },
              "& .MuiInput-underline:after": {
                borderRadius: 30,
              },
              "& .MuiOutlinedInput-input": {
                py: 2,
                px: { xs: 2, md: 3 },
                fontSize: 16,
              },
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  border: "1px solid #000",
                  borderRadius: 30,
                },
                "&:hover fieldset": {
                  borderRadius: 30,
                },
                "&.Mui-focused fieldset": {
                  borderRadius: 30,
                },
              },
            }}
          />
          <Button
            sx={{
              textTransform: "none",
              fontSize: { xs: 14, md: 16 },
              borderRadius: 30,
              backgroundColor: "#000",
              p: 2,
              height: "55px",
              fontWeight: 400,
              color: "#fff",
              mt: { xs: 1, sm: 2 },
              mb: 0,
              "& i": {
                mr: 1,
              },
              "&:hover,&:focus": { bgcolor: "#000" },
            }}
            type="submit"
          >
            <i className="fa-light fa-envelope" />
            {component.ucfirst("subscribe-btn")}
          </Button>
          <CardMedia
            component="img"
            sx={{
              position: { xs: "relative", md: "absolute" },
              right: { xs: "unset", md: 30 },
              bottom: { xs: "unset", md: -71 },
              width: { xs: 160, md: 200 },
              mx: "auto",
              my: { xs: 5, md: 0 },
              display: { xs: "none", md: "block" },
            }}
            image={filesUrl.concat(
              `/marketing/square_logo.png?v=${img_version}`
            )}
            alt="il Palatino"
          />
        </Box>
      </Container>
    </Box>
  );
}
