import { Component } from "../../../modules/Core/Component";
import templateDefault from "../../templates/default/widgets/navbar/slider";
import Services from "../../Services/Services";

export default class Slider extends Component {
  group = "slider";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.getSliders();
  }

  getSliders() {
    Services.get("item").then(([itemService]) => {
      itemService
        .getAllSliders()
        .then((itemService) => {
          const sliders = itemService.getData("sliders");
          this.setData({
            "default.sliders": sliders.rows.sort(function (a, b) {
              a.position =
                typeof a._values.position === "undefined"
                  ? 10000
                  : a._values.position;
              b.position =
                typeof b._values.position === "undefined"
                  ? 10000
                  : b._values.position;

              return a._values.position - b._values.position;
            }),
            "default.slider_index": 0,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }

  changeSlide(index) {
    this.getComponents()
      .findByGroup("slider")
      .forEach((comp) => {
        comp.setData({ "default.slider_index": index });
      });
  }
}
