import Home from "./Page/Home";
import Checkout from "./Page/Checkout";
import TermsAndConditions from "./Page/TermsAndConditions";

// User Section
import Profile from "./Page/User/Profile";
import Addresses from "./Page/User/Addresses";
import Orders from "./Page/User/Orders";
import Order from "./Page/User/Order";
import Coupons from "./Page/User/Coupons";

// Epay
import EpayResult from "./Page/Epay/Result";

// Redirect Section
import Redirect from "./Page/Redirect";

const Module = {
  Home,
  Redirect,
  Profile,
  Addresses,
  Orders,
  Order,
  Coupons,
  Checkout,
  EpayResult,
  TermsAndConditions,
};

export default Module;
