import Helpers from "../../modules/Core/Helpers/Helpers";

export default class Service {
  data = {};

  evaluate(r, name) {
    let valid = r.isValid();
    const data = r.getData();

    if (r.isValid() && name) {
      this.setData(name, data);
    }

    this.setData("_response", r);

    return valid;
  }

  setData(path, data) {
    this.data = this.data || {};
    Helpers.Dev.setObjectValue(this.data, path, data);
    return this;
  }

  getData(path, defaultValue) {
    this.data = this.data || {};
    return Helpers.Dev.getObjectValue(this.data, path, defaultValue);
  }

  getResponse() {
    return this.getData("_response");
  }

  getMessage() {
    const response = this.getResponse();
    return response ? response.getMessage() : "";
  }

  setError(value) {
    return this.setData("_error", value || null);
  }

  getError() {
    return this.getData("_error");
  }
}
