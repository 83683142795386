import React from "react";
import { styled } from "@mui/material/styles";
import { Grid, TextField } from "@mui/material";

const MyTextField = styled(TextField)(({ theme }) => ({
  marginTop: "8px",
  marginBottom: "8px",
  "& label.Mui-focused": {
    color: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#7d7d7d",
    borderRadius: "12px",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&:hover fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#7d7d7d",
      borderRadius: "12px",
    },
  },
}));

export default function Address(props) {
  const { component, type, fullAddress = true } = props;
  var order = "";

  if (type !== "address") {
    order = "order.";
  }

  const changeValue = (value, attr) => {
    const shippingAddress = component.getData("default." + order + "" + type);

    if (shippingAddress) {
      component.setData({
        ["default." + order + "" + type + "." + attr]: value,
      });
    } else {
      component.setData({
        ["default." + order + "" + type]: { [attr]: value },
      });
    }

    component.deleteData("error." + type + "." + attr);
  };

  const getCoordinates = () => {
    var address = component.getData("default." + order + "" + type, {});
    component.getCoordinates(
      `${address.street} ${address.street_number}, ${address.city}, ${address.state} ${address.postal_code}`,
      type
    );
  };

  return (
    <Grid container justifycontent="center" spacing={1}>
      <Grid item xs={12}>
        <MyTextField
          id={type !== "address" ? type + "_autocomplete" : "autocomplete"}
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("street_address-textfield")}
          name="street"
          helperText={
            component.getData("error." + type + ".street", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".street",
            ""
          )}
          onChange={(e) => changeValue(e.target.value, "street")}
          error={component.dataExists("error." + type + ".street")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("street_number-textfield")}
          name="street_number"
          helperText={
            component.getData("error." + type + ".street_number", [
              { message: "" },
            ])[0].message
          }
          value={component.getData(
            "default." + order + "" + type + ".street_number",
            ""
          )}
          onChange={(e) => changeValue(e.target.value, "street_number")}
          onBlur={(e) => getCoordinates()}
          error={component.dataExists("error." + type + ".street_number")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("city-textfield")}
          name="city"
          helperText={
            component.getData("error." + type + ".city", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".city",
            ""
          )}
          onChange={(e) => changeValue(e.target.value, "city")}
          onBlur={(e) => getCoordinates()}
          error={component.dataExists("error." + type + ".city")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <MyTextField
          variant="outlined"
          margin="normal"
          fullWidth
          label={component.ucfirst("state-textfield")}
          name="state"
          helperText={
            component.getData("error." + type + ".state", [{ message: "" }])[0]
              .message
          }
          value={component.getData(
            "default." + order + "" + type + ".state",
            ""
          )}
          onChange={(e) => changeValue(e.target.value, "state")}
          onBlur={(e) => getCoordinates()}
          error={component.dataExists("error." + type + ".state")}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <Grid item xs={12}>
          <MyTextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={component.ucfirst("postal_code-textfield")}
            name="postal_code"
            helperText={
              component.getData("error." + type + ".postal_code", [
                { message: "" },
              ])[0].message
            }
            value={component.getData(
              "default." + order + "" + type + ".postal_code",
              ""
            )}
            onChange={(e) => changeValue(e.target.value, "postal_code")}
            onBlur={(e) => getCoordinates()}
            error={component.dataExists("error." + type + ".postal_code")}
          />
        </Grid>
      </Grid>
      {fullAddress && (
        <>
          <Grid item xs={12}>
            <MyTextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={component.ucfirst("phone-textfield")}
              name="phone"
              helperText={
                component.getData("error." + type + ".phone", [
                  { message: "" },
                ])[0].message
              }
              value={component.getData(
                "default." + order + "" + type + ".phone",
                ""
              )}
              onChange={(e) => changeValue(e.target.value, "phone")}
              error={component.dataExists("error." + type + ".phone")}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MyTextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={component.ucfirst("bell-textfield")}
              name="bell"
              helperText={
                component.getData("error." + type + ".bell", [
                  { message: "" },
                ])[0].message
              }
              value={component.getData(
                "default." + order + "" + type + ".bell",
                ""
              )}
              onChange={(e) => changeValue(e.target.value, "bell")}
              error={component.dataExists("error." + type + ".bell")}
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <MyTextField
              variant="outlined"
              margin="normal"
              fullWidth
              label={component.ucfirst("floor-textfield")}
              name="floor"
              helperText={
                component.getData("error." + type + ".floor", [
                  { message: "" },
                ])[0].message
              }
              value={component.getData(
                "default." + order + "" + type + ".floor",
                ""
              )}
              onChange={(e) => changeValue(e.target.value, "floor")}
              error={component.dataExists("error." + type + ".floor")}
            />
          </Grid>

          <Grid item xs={12}>
            <MyTextField
              variant="outlined"
              margin="normal"
              multiline
              fullWidth
              rows={2}
              label={component.ucfirst("notes-textfield")}
              name="notes"
              helperText={
                component.getData("error." + type + ".notes", [
                  { message: "" },
                ])[0].message
              }
              value={component.getData(
                "default." + order + "" + type + ".notes",
                ""
              )}
              onChange={(e) => changeValue(e.target.value, "notes")}
              error={component.dataExists("error." + type + ".notes")}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
