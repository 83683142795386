import React from "react";
import { useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  useMediaQuery,
  Button,
  CircularProgress,
  Typography,
  Card,
  CardContent,
  Alert,
  Link,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 2,
        backgroundColor: "#f05d32",
        color: "#fff",
        display: "flex",
        alignItems: "center",
        position: "relative",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 0,
            top: "50%",
            transform: "translate(0,-50%)",
            color: "#fff",
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function DeliveryType(props) {
  const { component } = props;
  const devHelper = component.getHelpers("dev");

  const { open = false, spots = null } = component.getData("default", {});

  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  return (
    <Dialog
      onClose={() => {
        component.setData({ "default.open": false });
      }}
      open={open}
      fullScreen={fullScreen}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: 0, sm: "25px" },
        },
      }}
    >
      <MyDialogTitle
        onClose={() => {
          component.setData({ "default.open": false });
        }}
      >
        {component.ucfirst("select-spot-dialog-title")}
      </MyDialogTitle>

      <DialogContent dividers sx={{ p: 2 }}>
        {!spots ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <CircularProgress
              sx={{
                mt: 5,
                color: "#f05d32",
              }}
            />
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {spots.length !== 0 ? (
              <>
                {spots.map((spot) => {
                  const { slug, url, phone } = devHelper.getObjectValue(
                    spot,
                    "_values"
                  );
                  return (
                    <Card
                      key={slug}
                      sx={{
                        display: "flex",
                        my: "4px",
                        width: "100%",
                        bgcolor: "#f2f2f2",
                        borderColor: "#f2f2f2",
                        borderRadius: "25px !important",
                      }}
                      variant="outlined"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: "12px",
                        }}
                      >
                        <CardContent sx={{ flex: 1, p: 0 }}>
                          <Typography
                            sx={{
                              fontSize: "25px",
                              fontWeight: 500,
                            }}
                          >
                            {component.trans(`${slug}-name`)}
                          </Typography>
                          <Box sx={{ py: 2 }}>
                            <Box
                              sx={{ fontSize: "16px", color: "#696963", my: 1 }}
                            >
                              <i className="fa-light fa-location-dot"></i>
                              <Link
                                href={url}
                                rel="noreferrer"
                                target="_blank"
                                underline="none"
                                sx={{
                                  color: "#696963",
                                  ml: 1,
                                }}
                              >
                                {component.trans(`${slug}-address`)}
                              </Link>
                            </Box>
                            <Box
                              sx={{ fontSize: "16px", color: "#696963", my: 1 }}
                            >
                              <i className="fa-light fa-phone"></i>
                              <Link
                                href={"tel:" + phone}
                                underline="none"
                                sx={{
                                  color: "#696963",
                                  ml: 1,
                                }}
                              >
                                {phone}
                              </Link>
                            </Box>
                            <Box
                              sx={{ fontSize: "16px", color: "#696963", my: 1 }}
                            >
                              <i className="fa-light fa-clock"></i>
                              <span
                                style={{
                                  marginLeft: "8px",
                                }}
                              >
                                {component.trans(`${slug}-hours`)}
                              </span>
                            </Box>
                          </Box>
                        </CardContent>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Button
                            onClick={() =>
                              component.updateSpot(
                                devHelper.getObjectValue(spot, "_id")
                              )
                            }
                            fullWidth
                            sx={{
                              bgcolor: "#f05d32",
                              borderRadius: "50px",
                              border: "1px solid #f05d32",
                              color: "#fff",
                              fontSize: "15px",
                              px: 2,
                              textTransform: "none",
                              "&:hover": {
                                background: "#f05d32",
                              },
                            }}
                          >
                            <i
                              className="fa-light fa-store"
                              style={{ marginRight: "8px", fontSize: 20 }}
                            />
                            {component.ucfirst("order-here")}
                          </Button>
                        </Box>
                      </Box>
                      {/* <CardMedia
                        component="img"
                        sx={{
                          display: { xs: "none", sm: "block" },
                          width: { xs: "140px", sm: "200px" },
                        }}
                        image={component
                          .getHelpers("url")
                          .createAccountImageUrl(
                            account,
                            repository,
                            "item",
                            "spot",
                            "default",
                            slug
                          )}
                        alt={slug + " image"}
                      /> */}
                    </Card>
                  );
                })}
              </>
            ) : (
              <Box
                sx={{
                  minHeight: 100,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography align="center">
                  <Alert severity="info">
                    {component.ucfirst("zero-spots")}
                  </Alert>
                </Typography>
              </Box>
            )}
          </Box>
        )}
      </DialogContent>
    </Dialog>
  );
}
