import * as React from "react";
import {
  Box,
  Typography,
  Link,
  Grid,
  Container,
  Button,
  CardMedia,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Link as RouterLink } from "react-router-dom";
import SubscriptionSection from "../../widgets/footer/subscription_section";
import ContactUs from "../../../../Components/Widgets/Dialogs/ContactUs";
import { isMobile } from "react-device-detect";

function DevelopedBy() {
  return (
    <Box sx={{ bgcolor: "#e8e8e8", pt: 2, pb: isMobile ? "66px" : 2 }}>
      <Typography variant="body2" align="center" sx={{ color: "#818181" }}>
        {"Developed by "}
        <Link
          color="inherit"
          href="https://www.automatapp.com/"
          underline="none"
          sx={{ fontWeight: 600 }}
        >
          Automatapp
        </Link>{" "}
        {new Date().getFullYear()}
      </Typography>
    </Box>
  );
}

const FooterTitle = styled(Typography)(({ theme }) => ({
  fontSize: "24px",
  color: "#fff",
  fontWeight: "600",
  marginBottom: "15px",
}));

const InfoLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "#fff",
  "& i": {
    fontSize: "20px",
    marginRight: "8px",
  },
}));

export default function StickyFooter(props) {
  const { component } = props;
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const [contactUsOpen, setContactUsOpen] = React.useState(false);

  const myLink = {
    textDecoration: "none",
    color: "#d1d1d1",
    marginBottom: "16px",
    display: "flex",
    alignItems: "center",
    fontSize: "20px",
    fontWeight: 500,
    "& i": {
      minWidth: 45,
      fontSize: "30px",
    },
    "& div": {
      textAlign: "center",
      display: "inline-flex",
    },
    "&:hover": {
      color: "#fff",
    },
  };

  const apps = [
    {
      slug: "wolt",
      name: "Wolt",
      url: "https://wolt.com/el/grc/athens/restaurant/il-palatino-sevastoupoleos",
    },
    {
      slug: "box",
      name: "Box",
      url: "https://box.gr/delivery/ampelokipoi/il-palatino-ampelokipoi",
    },
    {
      slug: "efood",
      name: "Efood",
      url: "https://www.e-food.gr/delivery/athina/il-palatino",
    },
  ];

  return (
    <Box
      component="footer"
      sx={{
        mt: "auto",
        backgroundColor: "#000",
      }}
    >
      <SubscriptionSection {...props} />
      <Container maxWidth="lg" sx={{ pb: 1, pt: 4 }}>
        <Grid
          container
          direction="row"
          alignItems="flex-start"
          rowSpacing={{ xs: 3 }}
          columnSpacing={{ xs: 6 }}
        >
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>{component.trans("footer-title-1")}</FooterTitle>
            <Link
              sx={myLink}
              href="https://www.google.com/maps/place/PIZZA+Il+Palatino/@37.9912039,23.7648425,17z/data=!3m2!4b1!5s0x14a19806db2da169:0x3e2f2324c08e09f1!4m5!3m4!1s0x14a19806c516739f:0xb2339eea7d0c632b!8m2!3d37.9911997!4d23.7670312"
              target="_blank"
            >
              <Box>
                <i className="fa-light fa-store" style={{ fontSize: "24px" }} />
              </Box>
              {component.trans("ampelokipoi")}
            </Link>
            <Link
              sx={myLink}
              href={"tel:" + component.trans("ampelokipoi-phone-number")}
            >
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("ampelokipoi-phone-number")}
            </Link>
            <Box sx={{ mb: 2, borderTop: "1px solid #d1d1d1", mr: "70%" }} />
            <Link
              sx={myLink}
              href="https://www.google.com/maps/place/Pizza+IL+Palatino+%CE%96%CF%89%CE%B3%CF%81%CE%AC%CF%86%CE%BF%CF%85/@37.9828913,23.7601499,17z/data=!3m1!4b1!4m5!3m4!1s0x14a1bd5593cd9781:0x3856fd2097baefec!8m2!3d37.9828871!4d23.7623386"
              target="_blank"
            >
              <Box>
                <i className="fa-light fa-store" style={{ fontSize: "24px" }} />
              </Box>
              {component.trans("zografou")}
            </Link>
            <Link
              sx={myLink}
              href={"tel:" + component.trans("zografou-phone-number")}
            >
              <Box>
                <i className="fa-light fa-circle-phone" />
              </Box>
              {component.trans("zografou-phone-number")}
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>Delivery</FooterTitle>
            {apps.map((app) => {
              const { slug, name, url } = app;

              return (
                <Link
                  key={slug}
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  sx={{ display: "inline-block", mr: 1 }}
                >
                  <CardMedia
                    sx={{
                      width: "80px",
                    }}
                    component="img"
                    image={`${filesUrl}/assets/images/${slug}.png?v=${img_version}`}
                    alt={`${name} image`}
                  />
                </Link>
              );
            })}
            <FooterTitle>{component.trans("footer-title-3")}</FooterTitle>
            <Box sx={{ mb: 2 }}>
              <InfoLink
                href="https://www.facebook.com/ilpalatino.gr"
                target="_blank"
                aria-label="facebook"
              >
                <i
                  className="fa-brands fa-facebook"
                  style={{ fontSize: "35px" }}
                />
              </InfoLink>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} lg={4}>
            <FooterTitle>{component.trans("footer-title-2")}</FooterTitle>
            <Link
              sx={myLink}
              href={"mailto:" + component.trans("shop-email")}
              target="_blank"
            >
              <Box>
                <i className="fa-light fa-circle-envelope" />
              </Box>
              {component.trans("shop-email")}
            </Link>
            <Button
              sx={myLink}
              style={{ padding: 0, textTransform: "none" }}
              onClick={() => setContactUsOpen(true)}
            >
              <Box>
                <i className="fa-light fa-comment-question" />
              </Box>
              {component.trans("contact-form")}
            </Button>
            <Box
              component="img"
              sx={{ height: { xs: 45, md: 50 }, mt: 2 }}
              alt="payment-type-cards"
              src="/images/cards.png"
            />
          </Grid>
        </Grid>
        <Typography
          sx={{
            fontSize: 14,
            color: "#fff",
            mt: 3,
            textAlign: { xs: "center", md: "right" },
          }}
        >
          {`Copyright © il Palatino ${new Date().getFullYear()} - `}{" "}
          <Link
            component={RouterLink}
            to="/terms-and-conditions/terms-of-use"
            underline="hover"
            sx={{
              color: "#fff",
              "&:hover": {
                color: "#d1d1d1",
              },
            }}
          >
            {component.trans("terms-and-conditions")}
          </Link>
        </Typography>
      </Container>
      <DevelopedBy />
      <ContactUs
        {...{
          ...props,
          ...{
            open: contactUsOpen,
            setOpen: (boolean) => setContactUsOpen(boolean),
          },
        }}
      />
    </Box>
  );
}
