import React from "react";
import {
  Snackbar,
  Fade,
  Alert as MuiAlert,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Alert = React.forwardRef(function Alert(props, ref) {
  return (
    <MuiAlert
      elevation={1}
      ref={ref}
      variant="filled"
      {...props}
      sx={{ borderRadius: "12px" }}
    />
  );
});

export default function MainMessage(props) {
  const { component } = props;

  const theme = useTheme();
  const appbarHeight = useMediaQuery(theme.breakpoints.up("md"))
    ? theme.appbar_height.desktop
    : theme.appbar_height.mobile;

  const successMessage = component.getData("success-message", "");
  const errorMessage = component.getData("error-message", "");
  const messageDuration = component.getData("message-duration", 3000);

  const handleClose = () => {
    component.setData({ "success-message": "" });
    component.setData({ "error-message": "" });
    component.setData({ "message-duration": 3000 });
  };

  return (
    <>
      <Snackbar
        open={successMessage !== ""}
        autoHideDuration={messageDuration}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{
          maxWidth: "unset",
          mt: appbarHeight,
          "& .MuiAlert-root": {
            backgroundColor: "#f05d32",
          },
        }}
      >
        <Alert severity="success">{component.ucfirst(successMessage)}</Alert>
      </Snackbar>
      <Snackbar
        open={errorMessage !== ""}
        autoHideDuration={messageDuration}
        onClose={handleClose}
        TransitionComponent={Fade}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        sx={{
          maxWidth: "unset",
          mt: appbarHeight,
        }}
      >
        <Alert severity="error">{component.ucfirst(errorMessage)}</Alert>
      </Snackbar>
    </>
  );
}
