import Item from "./Item";
import Url from "./Url";
import Wishlist from "./Wishlist";
import Order from "./Order";
import Address from "./Address";
import Auth from "./Auth";
import User from "./User";
import Coupon from "./Coupon";
import SocialLogin from "./SocialLogin";
import Hook from "./Hook";
import Language from "./Language";

const ExportDefault = {
  Item,
  Url,
  Wishlist,
  Order,
  Address,
  Auth,
  User,
  Coupon,
  SocialLogin,
  Hook,
  Language,
};

export default ExportDefault;
