import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddressForm from "./address_form";
import useMediaQuery from "@mui/material/useMediaQuery";

const SubmitBtn = styled(Button)(({ theme }) => ({
  padding: "12px 14px",
  background: "#f05d32",
  borderRadius: "8px",
  border: "1px solid #f05d32",
  color: "#ffffff",
  "&:hover": {
    background: "#f05d32",
  },
}));

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function Address(props) {
  const { component } = props;
  const { open = false } = component.props;
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));

  return (
    <Dialog
      onClose={() => {
        component.props.setOpen(false);
        component.resetAddress();
      }}
      open={open}
      fullScreen={fullScreen}
    >
      <form onSubmit={(e) => component.addAddress(e)}>
        <MyDialogTitle
          onClose={() => {
            component.props.setOpen(false);
            component.resetAddress();
          }}
        >
          {component.ucfirst("add-new-address-dialog-title")}
        </MyDialogTitle>
        <DialogContent dividers sx={{ p: 1 }}>
          <AddressForm
            {...{
              ...props,
              ...{
                type: "address",
              },
            }}
          />
        </DialogContent>
        <DialogActions sx={{ p: 1 }}>
          <SubmitBtn type="submit" fullWidth variant="contained">
            {component.ucfirst("submit-btn")}
          </SubmitBtn>
        </DialogActions>
      </form>
    </Dialog>
  );
}
