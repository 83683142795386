import { Component } from "../../../../modules/Core/Component";
import templateDefault from "../../../templates/default/widgets/dialogs/select_address_dialog";
import Services from "../../../Services/Services";

export default class SelectAddressDialog extends Component {
  id = "select-address-dialog";
  group = "order";

  template = templateDefault;

  default_address = {
    street: "",
    street_number: "",
    city: "",
    postal_code: "",
    phone: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    bell: "",
    floor: "",
    notes: "",
  };

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });
  }

  retrieveAddresses() {
    Services.get("order,address").then(
      async ([orderService, addressService]) => {
        try {
          await orderService.fetchOrder();
          await addressService.getAddresses();
          const addresses = addressService.getData("addresses");

          const order = orderService.getData("order");

          this.setData({
            "default.addresses": addresses,
            "default.order": order,
          });

          if (
            order &&
            order.shipping_address &&
            order.shipping_address.lat !== ""
          ) {
            this.setNewMarker(order.shipping_address);
          } else {
            this.setNewMarker();
          }
        } catch (orderService) {
          this.checkOrderError(orderService);
        }
      }
    );

    return this;
  }

  addAddress(e, hideAddressForm) {
    e.preventDefault();

    this.setData({
      error: {
        shipping_address: {},
      },
    });

    var { addresses } = this.getData("default");
    var { shipping_address = null } = this.getData("default.order");
    var shippingAddressId = null;
    if (hideAddressForm && addresses.length !== 0) {
      shippingAddressId = this.findAddressSelected("shipping");
    } else {
      shipping_address = this.validateAddress(
        shipping_address,
        "shipping_address"
      );
    }

    const error = this.getData("error", {});

    if (Object.keys(error.shipping_address).length === 0) {
      if (hideAddressForm && addresses.length !== 0) {
        this.setData({
          "default.shippingAddressId": shippingAddressId,
        });
        this.updateAddress();
        this.setData({
          "default.open": false,
        });
      } else {
        Services.get("address").then(([addressService]) => {
          addressService
            .createAddress(shipping_address)
            .then((addressService) => {
              return addressService.getAddresses();
            })
            .then((addressService) => {
              const shippingAddressId = addressService
                .getData("addresses")
                .reverse()[0].id;
              const addresses = addressService.getData("addresses");
              this.setData({
                "default.addresses": addresses,
                "default.shippingAddressId": shippingAddressId,
              });
              this.updateAddress({
                shippingAddressId,
              });
              this.setData({
                "default.open": false,
              });
            })
            .catch((err) => {
              console.log("error", err);
            });
        });
      }
    }
  }

  findAddressSelected(type) {
    var id = 0;
    var orders_address =
      this.getData(`default.order.${type}_address`, {}) || {};
    const addresses = this.getData("default.addresses", []) || [];
    if (orders_address) {
      if (orders_address._id) {
        addresses.forEach((address) => {
          if (address.id === orders_address._id) {
            id = orders_address._id;
          }
        });
      }
      if (id === 0) {
        id = addresses[0].id;
      }
    }

    return id;
  }

  selectAddress(addressId, type) {
    this.setData({ [`default.${type}AddressId`]: addressId });
    this.updateAddress();
  }

  updateAddress(data) {
    const {
      shipping_address = {},
      billing_address = {},
      shippingAddressId = null,
      billingAddressId = null,
    } = data || this.getData("default", {});

    Services.get("order").then(async ([orderService]) => {
      orderService
        .updateAddress({
          shippingAddress: shipping_address,
          billingAddress: billing_address,
          shippingAddressId,
          billingAddressId,
        })
        .then((orderService) => {
          const order = orderService.getData("order");
          this.getComponents()
            .findByGroup("order")
            .forEach((comp) => {
              comp.setData({ "default.order": order });
            });

          this.setNewMarker(order.shipping_address);
          this.getComponents().findById("products").first().fetchProducts();

          const { spot } = order;

          if (!spot) {
            this.getComponents()
              .findById("main-message")
              .first()
              .setData({
                "error-message": this.trans("delivery-not-available-yet"),
              });
          }
        })
        .catch((orderService) => {
          this.checkOrderError(orderService);
        });
    });

    return this;
  }

  validateAddress(address, type) {
    const error = this.getData("error", {});

    error[type] =
      this.getHelpers("validate").validate(address, {
        street: [
          {
            rule: "required",
            message: this.ucfirst("street_address-helpertext"),
          },
        ],
        street_number: [
          {
            rule: "required",
            message: this.ucfirst("street_number-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("street_number-helpertext-2"),
          },
        ],
        city: [
          {
            rule: "required",
            message: this.ucfirst("city-helpertext"),
          },
        ],
        state: [
          {
            rule: "required",
            message: this.ucfirst("state-helpertext"),
          },
        ],
        postal_code: [
          {
            rule: "required",
            message: this.ucfirst("postal_code-helpertext"),
          },
          {
            rule: "number",
            message: this.ucfirst("postal_code-helpertext-2"),
          },
          {
            rule: "range",
            message: this.ucfirst("postal_code-helpertext-2"),
            min: 5,
            max: 5,
          },
        ],
      }) || {};

    if (Object.keys(error[type]).length === 0) {
      address.address = `${address.street} ${address.street_number}, ${address.city}, ${address.state} ${address.postal_code}`;
    }

    this.setData({
      error,
    });

    return address;
  }

  initGoogleAutocomplete(type) {
    setTimeout(() => {
      let autocomplete;
      if (document.getElementById(type + "_autocomplete")) {
        autocomplete = new window.google.maps.places.Autocomplete(
          document.getElementById(type + "_autocomplete"),
          {
            componentRestrictions: { country: ["gr"] },
            fields: ["address_components", "geometry"],
            types: ["address"],
          }
        );

        autocomplete.addListener("place_changed", () => {
          var place = autocomplete.getPlace();
          if (place.geometry) {
            this.setData({
              error: {
                shipping_address: {},
              },
            });
            this.setAddress(place, type);
          }
        });
      }
    }, 500);
  }

  getCoordinates(address, type) {
    fetch(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        address +
        "&key=" +
        this.getHelpers("env").get("google_api")
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.results[0] && data.results[0].geometry) {
          this.setAddress(data.results[0], type, true);
        }
      });
  }

  setAddress(place, type, address_update) {
    const address = { ...this.default_address };
    const uluru = {
      lat: address_update
        ? place.geometry.location.lat
        : place.geometry.location.lat(),
      lng: address_update
        ? place.geometry.location.lng
        : place.geometry.location.lng(),
    };
    address["lat"] = uluru.lat;
    address["lng"] = uluru.lng;

    for (const component of place.address_components) {
      const componentType = component.types[0];

      switch (componentType) {
        case "route": {
          address["street"] = component.short_name;
          break;
        }
        case "street_number": {
          address["street_number"] = component.long_name;
          break;
        }
        case "locality": {
          address["city"] = component.short_name;
          break;
        }
        case "postal_code": {
          address["postal_code"] = component.long_name.replace(" ", "");
          break;
        }
        case "postal_code_suffix": {
          address[
            "postal_code"
          ] = `${address["postal_code"]}-${component.long_name}`;
          break;
        }
        case "administrative_area_level_1": {
          address["state"] = component.short_name;
          break;
        }
        case "administrative_area_level_3": {
          address["state"] = component.short_name;
          if (address["state"].includes("Αθηνών")) {
            address["state"] = "Αθήνα";
          }
          if (address["state"].includes("Athinon")) {
            address["state"] = "Athens";
          }
          break;
        }
        case "country":
          address["country"] = component.long_name;
          break;
        default:
          break;
      }
    }
    this.setNewMarker(address);
    this.setData({
      ["default.order." + type]: address,
    });
  }

  setNewMarker(address) {
    var map = new window.google.maps.Map(document.getElementById("map"), {
      zoom: address ? 18 : 13,
      center: { lat: 37.98381, lng: 23.727539 },
      zoomControl: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: true,
    });
    if (address && address.lat && address.lng) {
      const uluru = {
        lat: parseFloat(address.lat),
        lng: parseFloat(address.lng),
      };
      map.setCenter(uluru);
      new window.google.maps.Marker({
        map: map,
        position: uluru,
      });
    }
  }

  showAddressForm(type) {
    this.setNewMarker();
    this.setData({
      ["default.order." + type]: { ...this.default_address },
    });
    this.initGoogleAutocomplete(type);
  }

  checkOrderError(service) {
    if (service && service.getMessage && service.getError) {
      const type = service.getMessage() || service.getError();

      switch (type) {
        case "order-missing":
        case "order-expired":
        case "order-placed": {
          this.getComponents()
            .findById("main-message")
            .first()
            .setData({
              "error-message": this.trans(type),
            });
          break;
        }
        default:
          break;
      }
    }

    return this;
  }
}
