import { Component } from "../../../../modules/Core/Component";
// import Helpers from "../../../../modules/Core/Helpers/Helpers";
import templateDefault from "../../../templates/default/widgets/dialogs/offer_dialog";

export default class OfferDialog extends Component {
  id = "offer-dialog";

  template = templateDefault;

  onLoad(data) {
    super.onLoad(data);

    this.setData({
      "default.open": false,
    });
    // const Env = Helpers.Env;
    // const stateHelper = Helpers.State;

    // var offer = stateHelper.get("offer");
    // this.setData({
    //   "default.open": !offer ? true : false,
    // });

    // if (!offer) {
    //   stateHelper.set("offer", true, {
    //     domain: Env.get("domain"),
    //     path: "/",
    //   });
    // }
  }

  toggle(boolean) {
    this.setData({
      "default.open": boolean,
    });
  }
}
