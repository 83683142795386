import React from "react";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  Skeleton,
  ListItemText,
  CardMedia,
  FormControl,
  Button,
  useScrollTrigger,
  Collapse,
  ButtonGroup,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Translate from "../../../../../modules/Core/Components/Translate";

const MyDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 0,
        display: "flex",
        alignItems: "center",
      }}
      {...other}
    >
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            color: "#000",
            padding: "4px",
            backgroundColor: "#fff",
            border: "1px solid #fff",
            borderRadius: "100%",
            zIndex: 1302,
            "&:hover": {
              backgroundColor: "#fff",
            },
          }}
        >
          <CloseIcon sx={{ fontSize: "20px" }} />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

export default function ProductDialog(props) {
  const { component } = props;
  const ref = React.useRef();

  function ShowOnScroll(props) {
    const { children } = props;
    const trigger = useScrollTrigger({
      target: ref.current ? ref.current : undefined,
      disableHysteresis: true,
      threshold: useMediaQuery(useTheme().breakpoints.down("sm")) ? 300 : 350,
    });

    return (
      <Collapse
        appear={false}
        direction="down"
        in={trigger}
        timeout={{ enter: 0, exit: 0 }}
      >
        {children}
      </Collapse>
    );
  }

  const devHelper = component.getHelpers("dev");
  const filesUrl = component.getHelpers("env").get("files_url");
  const img_version = component.getHelpers("value").getValue("images-version");

  const fullScreen = useMediaQuery(useTheme().breakpoints.down("sm"));

  const { open = false, product = null } = component.getData("default", {});

  const setQnt = (qnt) => {
    product.amount = product.amount + qnt;
    component.setData({ "default.product": product });
  };

  const Title = () => {
    return (
      <ListItemText
        sx={{
          px: 2,
          py: 1,
          m: 0,
          backgroundColor: "#fff",
          width: "100%",
          boxShadow:
            devHelper.getObjectValue(product, "item.ingredients", []).length !==
            0
              ? "4px 4px 8px 0 rgb(0 0 0 / 8%)"
              : "none",
        }}
        primary={
          <span
            style={{
              fontSize: "16px",
              fontWeight: 600,
            }}
          >
            <Translate
              entity="item"
              eslug={devHelper.getObjectValue(product, "item.slug")}
              scope="default"
              slug="name"
              auto
            />
          </span>
        }
        secondary={
          <>
            <Translate
              entity="item"
              eslug={devHelper.getObjectValue(product, "item.slug")}
              scope="default"
              slug="description"
              auto
            />
            <Box sx={{ mt: 1 }}>
              {devHelper.getObjectValue(product, "price", 0) > 0 && (
                <Box
                  component="span"
                  sx={{
                    fontSize: 16,
                    fontWeight: 500,
                    color: "#000",
                  }}
                >
                  {component
                    .getApp()
                    .priceFormater(
                      devHelper.getObjectValue(product, "special_price", 0) > 0
                        ? devHelper.getObjectValue(product, "special_price", 0)
                        : devHelper.getObjectValue(product, "price", 0)
                    )}
                </Box>
              )}
              {devHelper.getObjectValue(product, "special_price", 0) > 0 && (
                <Box
                  component="span"
                  sx={{
                    ml: 1,
                    textDecoration: "line-through",
                    color: "#f05d32",
                  }}
                >
                  <Box
                    component="span"
                    sx={{
                      fontSize: 14,
                      color: "#777",
                      fontWeight: 400,
                    }}
                  >
                    {component
                      .getApp()
                      .priceFormater(
                        devHelper.getObjectValue(product, "price")
                      )}
                  </Box>
                </Box>
              )}
            </Box>
          </>
        }
        secondaryTypographyProps={{ sx: { mt: 1 }, component: "div" }}
      />
    );
  };

  return (
    <Dialog
      onClose={() => component.closeDialog()}
      fullScreen={fullScreen}
      open={open}
      sx={{
        "& .MuiPaper-rounded": {
          borderRadius: { xs: "0px", sm: "25px" },
          minWidth: { xs: "unset", md: "700px" },
          bgcolor: "transparent",
        },
        "& .MuiDialogContent-root::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <Box sx={{ position: "relative" }}>
        <ShowOnScroll {...props}>
          <Box
            sx={{
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 1301,
              width: "100%",
            }}
          >
            {Title()}
          </Box>
        </ShowOnScroll>
      </Box>
      <MyDialogTitle onClose={() => component.closeDialog()}></MyDialogTitle>
      <DialogContent
        ref={ref}
        sx={{
          backgroundColor:
            devHelper.getObjectValue(product, "item.ingredients", []).length !==
            0
              ? "#f7f7f7"
              : "#fff",
          p: 0,
          maxHeight: { xs: "unset", sm: "640px" },
        }}
      >
        <Box
          sx={{
            height: { xs: "300px", sm: "350px" },
          }}
        >
          {!component.getData("default.imageReady", false) && (
            <Skeleton variant="rectangular" width="100%" height="100%" />
          )}
          <CardMedia
            component="img"
            alt={devHelper.getObjectValue(product, "item.name") + " image"}
            sx={{
              maxWidth: "100%",
              height: "100%",
            }}
            src={`${filesUrl}/products/images/${devHelper.getObjectValue(
              product,
              "item.slug"
            )}.png?v=${img_version}`}
            onLoad={() => {
              component.setData({ "default.imageReady": true });
            }}
          />
        </Box>
        {Title()}
        <Box sx={{ px: 2, pt: 2 }}>
          {component
            // devHelper.getObjectValue(product, "item.products", [])
            .getTypes()
            .map((type, index) => {
              const key = `select-${type}-${index}`;

              return (
                <FormControl key={key} fullWidth sx={{ mb: 2 }}>
                  <InputLabel>{component.ucfirst(`select-${type}`)}</InputLabel>
                  <Select
                    // labelId="demo-simple-select-label"
                    // id="demo-simple-select"
                    value={component.getProduct(index)}
                    label={component.ucfirst(`select-${type}`)}
                    onChange={(e) => {
                      component.addProduct(e.target.value, index);
                      //   component.setData({
                      //     [`default.selectedProducts${index}`]: e.target.value,
                      //   });
                    }}
                  >
                    {component.getProductsByType(type).map((product) => {
                      const slug = devHelper.getObjectValue(
                        product,
                        "item._values.slug"
                      );

                      // const name = devHelper.getObjectValue(
                      //   product,
                      //   "item._values.name"
                      // );

                      // const price = devHelper.getObjectValue(
                      //   product,
                      //   "item._values.price"
                      // );

                      return (
                        <MenuItem key={slug} value={slug}>
                          {/* {name} */}
                          <Translate
                            entity="item"
                            eslug={slug}
                            scope="default"
                            slug="name"
                            auto
                          />
                          {/* {component.getApp().priceFormater(price)} */}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              );
            })}
        </Box>
      </DialogContent>
      <DialogActions
        sx={{
          boxShadow:
            devHelper.getObjectValue(product, "_values.ingredients", [])
              .length !== 0
              ? { xs: "none", sm: "0 -4px 12px 0 rgb(0 0 0 / 5%)" }
              : "none",
          pt: { xs: 0, sm: 1 },
          pb: 1,
          px: "4px",
          bgcolor: { xs: "#f7f7f7", sm: "#fff" },
        }}
      >
        <Grid
          container
          rowSpacing={{ xs: 1 }}
          columnSpacing={{ xs: "4px", sm: 1 }}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid item xs={6} sm={4}>
            <ButtonGroup
              fullWidth
              sx={{
                display: "flex",
                bgcolor: "#dddad4",
                mx: "auto",
                borderRadius: "25px",
                "& .MuiButton-root": {
                  fontSize: { xs: 13, sm: 16 },
                  border: "none",
                  color: "#000",
                  height: "44px",
                  px: { xs: "5px", sm: "15px" },
                  "&:hover": {
                    border: "none",
                    opacity: 0.8,
                    backgroundColor: "transparent",
                  },
                  "&:disabled": {
                    fontSize: { xs: 13, sm: 16 },
                    border: "none",
                    color: "#7d7d7d",
                  },
                },
              }}
            >
              <Button
                onClick={() => setQnt(-1)}
                disabled={devHelper.getObjectValue(product, "amount") === 1}
                sx={{
                  borderTopLeftRadius: "25px",
                  borderBottomLeftRadius: "25px",
                }}
              >
                <i className="fa-solid fa-minus" />
              </Button>
              <Button
                disabled
                sx={{
                  "&:disabled": {
                    height: "36px",
                    mt: 0.5,
                    fontSize: "16px",
                    color: "#fff !important",
                    bgcolor: "#000",
                    borderRadius: "25px !important",
                  },
                }}
              >
                {devHelper.getObjectValue(product, "amount")}
              </Button>
              <Button
                onClick={() => setQnt(1)}
                sx={{
                  borderTopRightRadius: "25px",
                  borderBottomRightRadius: "25px",
                }}
              >
                <i className="fa-solid fa-plus" />
              </Button>
            </ButtonGroup>
          </Grid>
          <Grid item xs={6} sm={6}>
            <Button
              fullWidth
              disabled={!component.hasProductSelected()}
              sx={{
                fontSize: { xs: 13, sm: 16 },
                border: "1px solid #f05d32",
                backgroundColor: "#f05d32",
                color: "#fff",
                borderRadius: "25px",
                height: "44px",
                "&:hover": {
                  backgroundColor: "#f05d32",
                  border: "1px solid #f05d32",
                },
              }}
              onClick={() => {
                component.handleClick(product);
              }}
            >
              {component.trans("add-to-cart-btn")}{" "}
              {component
                .getApp()
                .priceFormater(
                  devHelper.getObjectValue(product, "special_price", 0) > 0
                    ? devHelper.getObjectValue(product, "special_price") *
                        devHelper.getObjectValue(product, "amount")
                    : devHelper.getObjectValue(product, "price") *
                        devHelper.getObjectValue(product, "amount")
                )}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
